import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router'


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private authService:AuthService,
    private router:Router){

    }
  canActivate():boolean{
   if(this.authService.loggedIn()){
     return true;
   }
   this.router.navigate(['/login']);
   return false;
  }

  
  canLoad():boolean{
    if(this.authService.loggedIn()){
      const token = this.authService.getToken();
      const user = this.authService.decodeToken(token);
      if(user.role === 'admin'){
        return true;
      }
    }
    this.router.navigate(['/login']);
    return false;
   }
  
}
