import { FormGroup, ValidationErrors, ValidatorFn,AbstractControl } from "@angular/forms"
import { AuthService } from "../services/auth.service"
import { delay, map } from 'rxjs/operators';
export class Validation {
  static validarQueSeanIguales= (
    control: FormGroup
  ): ValidationErrors | null => {
    const password: any = control.get("password")
    const confirmarPassword: any = control.get("confirmarPassword")
  
    return password.value === confirmarPassword.value
      ? null
      : { noSonIguales: true }
  }

  static validateEmail(authService:AuthService){
    return (control: AbstractControl)=>{
      const value=control.value
      return authService.checkEmail(value)
      .pipe(
        delay(500),
        map(response =>{
          return !response.isExist ? null : {notAvailable:true}
        })
      )
    }
  }
}


 /* static passwordMatchValidator(control: AbstractControl | null) {
    const password: any = control.get('password').value; // get password from our password form control
    const confirmPassword: any = control.get('confirmarPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmarPassword').setErrors({ NoPassswordMatch: true });
    }
  }*/

