<footer class="bg-dark  pt-5">
    <div class="container">
      <div class="row pb-2">
        <div class="col-md-4 col-sm-6">
          <div class="widget widget-links widget-light pb-2 mb-4">
            <h3 class="widget-title text-light">Departamentos de tienda</h3>
            <ul class="widget-list">
              <ng-container *ngIf="categorias">
                <li class="widget-list-item" *ngFor="let category of categorias">
                  <a class="widget-list-link" routerLink="/categoria/{{category.url}}">{{category.titulo}}</a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="widget widget-links widget-light pb-2 mb-4">
            <h3 class="widget-title text-light">Cuenta &amp; Datos de envío</h3>
            <ul class="widget-list">
              <li class="widget-list-item"><a class="widget-list-link" routerLink="/login">Mi cuenta</a></li>
              <li class="widget-list-item"><a class="widget-list-link" routerLink="/tarifa-envio">Tarifas y políticas de envío</a></li>

              <li class="widget-list-item"><a class="widget-list-link" routerLink="/tracking">Rastreo de orden</a></li>
              <li class="widget-list-item"><a class="widget-list-link" routerLink="/">Información de entrega</a></li>

            </ul>
          </div>
          <div class="widget widget-links widget-light pb-2 mb-4">
            <h3 class="widget-title text-light">Sobre nosotros</h3>
            <ul class="widget-list">
              <li class="widget-list-item"><a class="widget-list-link" routerLink="/acerca">Acerca de la compañía</a></li>
              <li class="widget-list-item" hidden><a class="widget-list-link" routerLink="/nosotros">Nuestro equipo</a></li>
              <li class="widget-list-item" hidden><a class="widget-list-link" routerLink="/cursos">Cursos</a></li>
              <li class="widget-list-item"><a class="widget-list-link" routerLink="/catalogo">Catalogo</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-4">
          <div class="widget pb-2 mb-4">
            <h3 class="widget-title text-light pb-1">Mantente informado</h3>
            <form class="cz-subscribe-form validate" [formGroup]="formNewsletter" (submit)="OnPostNewsletterUser()">
              <div class="input-group input-group-overlay flex-nowrap">
                <div class="input-group-prepend-overlay">
                    <span class="input-group-text text-muted font-size-base">
                        <i class="far fa-envelope"></i>
                    </span>
                </div>
                <input class="form-control prepended-form-control" type="email" formControlName="email"  placeholder="Ingrese su email">
                <div class="input-group-append">
                  <button class="btn btn-purple" type="submit" name="subscribe" [disabled]="formNewsletter.invalid">Suscribete</button>
                </div>
              </div>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true">
                <input class="cz-subscribe-form-antispam" type="text" name="b_c7103e2c981361a6639545bd5_29ca296126" tabindex="-1">
              </div><small class="form-text text-light opacity-50">*Suscríbase a nuestro boletín para recibir ofertas de descuentos anticipados, actualizaciones e información sobre nuevos productos.</small>
              <div class="subscribe-status"></div>
            </form>
          </div>
          <div class="widget pb-2 mb-4">
            <div class="d-flex flex-wrap">
              <a class="d-inline-block align-middle mt-n1 mr-3" href="#">
                <img class="d-block" src="assets/image/logo/logodecoracionstoreblanco.png" alt="Seline Store" width="200">  
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5 bg-darker">
      <div class="container">
        <div class="row pb-3">
          <div class="col-md-3 col-sm-6 mb-4">
  
            <div class="media"><i class="fas fa-truck text-purple" style="font-size: 2.25rem;"></i>
              <div class="media-body pl-3">
                <h6 class="font-size-base text-light mb-1">entrega rápida y gratuita</h6>
                <p class="mb-0 font-size-ms text-light opacity-50">En la regíon de Concepcion</p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mb-4">
            <div class="media"><i class="fas fa-dollar-sign text-purple" style="font-size: 2.25rem;"></i>
              <div class="media-body pl-3">
                <h6 class="font-size-base text-light mb-1">Garantía de reembolso</h6>
                <p class="mb-0 font-size-ms text-light opacity-50">Devolvemos el dinero en 30 días</p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mb-4">
            <div class="media"><i class="fas fa-headset text-purple" style="font-size: 2.25rem;"></i>
              <div class="media-body pl-3">
                <h6 class="font-size-base text-light mb-1">24/7 Soporte</h6>
                <p class="mb-0 font-size-ms text-light opacity-50">Atención al cliente amigable 24/7</p>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-6 mb-4">
            <div class="media"><i class="far fa-credit-card text-purple" style="font-size: 2.25rem;"></i>
              <div class="media-body pl-3">
                <h6 class="font-size-base text-light mb-1">Pago seguro online</h6>
                <p class="mb-0 font-size-ms text-light opacity-50">Contamos con certificado SSL / seguro</p>
              </div>
            </div>
          </div>
        </div>
        <hr class="hr-light pb-4 mb-3">
        <div class="row pb-2">
          <div class="col-md-6 text-center text-md-left mb-4">
            <div class="text-nowrap mb-4">
            <a class="d-inline-block align-middle mt-n1 mr-3" href="#">
             
            </a>
             
            </div>
            <div class="widget widget-links widget-light">
              <ul class="widget-list d-flex flex-wrap justify-content-center justify-content-md-start">

                <li class="widget-list-item mr-4"><a class="widget-list-link" href="#">Afiliados</a></li>
                <li class="widget-list-item mr-4"><a class="widget-list-link" href="#">Soporte</a></li>
                <li class="widget-list-item mr-4"><a class="widget-list-link" href="#">Privacidad</a></li>
                <li class="widget-list-item mr-4"><a class="widget-list-link" href="#">Términos de Uso</a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 text-center text-md-right mb-4">
            <div class="mb-3">
                <a class="social-btn sb-light sb-whatsapp ml-2 mb-2" target="_blank" href="whatsapp://send?text=Prueba&phone=+56978956121&abid=+56978956121">
                  <i class="fab fa-whatsapp"></i>
                </a>
                <a class="social-btn sb-light sb-facebook ml-2 mb-2" target="_blank" href="https://www.facebook.com/decoracioneseline-105311417645913">
                    <i class="fab fa-facebook-f"></i>
                </a>
                <a class="social-btn sb-light sb-instagram ml-2 mb-2" target="_blank" href="https://www.instagram.com/selinestore_/">
                    <i class="fab fa-instagram"></i>
                </a>
             
                <a class="social-btn sb-light sb-youtube ml-2 mb-2" href="mailto:josdakhi2@gmail.com">
                  <i class="fab fa-google-plus-g"></i>
                </a>
            </div>
            <img class="d-inline-block" src="assets/image/general/medios-pago.png" alt="Metodos de pago" width="300px">
          </div>
        </div>
        <div class="pb-4 font-size-xs text-light opacity-50 text-center text-md-left">© Todos los derechos reservados Seline Store </div>
      </div>
    </div>
  </footer>
  <app-go-to class="z-index-999"></app-go-to>
  <app-toolbar  (sidebarToogle)="OnToogleSidebarRes($event)"></app-toolbar>
  