import { Component, OnInit } from '@angular/core';
import {FormGroup,FormBuilder,Validators} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { Router,ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-checkout-shipping',
  templateUrl: './checkout-shipping.component.html',
  styleUrls: ['./checkout-shipping.component.css']
})
export class CheckoutShippingComponent implements OnInit {
  numberItems:number=0;
  amountCart:number=0;
  token:string | null ='';
  data:any;
  breadcrumbitems={
    type:'product',
    title:'Revisa',
    items:[{
      title:"Compras",
      routerlink:'/categoria'
    },
    {
      title:'Revisa',
      routerLink:''
    }
    ]
  }
  type:string="right";
  constructor(
    private apiService:ApiService,
    private _router:Router,
  ) { }

  ngOnInit(): void {

    if (typeof(Storage) !== "undefined") {
      
      this.token=localStorage.getItem("token1");
   
      if(this.token!=null){
        this.getTokenCart(this.token);
      }
    
    } 
  }

  getTokenCart(token:string){
    this.apiService.getCart(token).subscribe(result=>{
      this.data = result;
      console.log(this.data);
    });
  }

}


