<app-navbar></app-navbar>
<main>
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container pb-5 mb-2 mb-md-4 page-title-overlapis">
        <div class="row">
            <section class="col-lg-8">
                <app-steps [numberActive]="4"></app-steps>
                <h2 class="h6 pb-3 mb-2">Elige el método de pago</h2>
                  <div class="">
                    <div class="accordion mb-2" id="payment-method" role="tablist">
                        <div class="card">
                          <div class="card-header" role="tab">
                            <h3 class="accordion-heading"><a href="#card" data-toggle="collapse" class="collapsed" aria-expanded="false"><i class="czi-card far fa-credit-card font-size-lg mr-2 mt-n1 align-middle"></i>Paga con tarjeta de crédito<span class="accordion-indicator"><i class="fas fa-chevron-up"></i></span></a></h3>
                          </div>
                          <div class="collapse" id="card" data-parent="#payment-method" role="tabpanel">
                            <div class="card-body">
                              <button class="btn btn-purple" (click)="postWepay()">Pagar por Webpay</button>
                            </div>
                          </div>
                        </div>
                
                        <div class="card">
                          <div class="card-header" role="tab">
                            <h3 class="accordion-heading"><a class="collapsed" href="#points" data-toggle="collapse" aria-expanded="false"><i class="czi-gift fas fa-university mr-2"></i>Transferencia Bancaria<span class="accordion-indicator"><i class="fas fa-chevron-up"></i></span></a></h3>
                          </div>
                          <div class="collapse" id="points" data-parent="#payment-method" role="tabpanel" style="">
                            <div class="card-body">
                              <p><span class="font-weight-medium">Banco:</span> Banco Santander</p>
                              <p><span class="font-weight-medium">N° Cuenta:</span> 79847836</p>
                              <p><span class="font-weight-medium">Tipo de cuenta:</span> Corriente</p>
                              <p><span class="font-weight-medium">Nombre:</span> Selime Decoraciones</p>
                              <p><span class="font-weight-medium">Email:</span> selinedecoraciones@gmail.com</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="d-none d-lg-flex pt-4">
                    <div class="w-50 pr-3">
                        <a class="btn btn-secondary btn-block" routerLink="/checkout-shipping">
                            <i class="czi-arrow-left fas fa-chevron-left mt-sm-0 mr-1"></i>
                            <span class="d-none d-sm-inline">Volver a Envíos</span>
                            <span class="d-inline d-sm-none">Volver</span>
                        </a>
                    </div>
                    <div class="w-50 pl-2">
                      <form>
                        <button class="btn btn-purple btn-block" routerLink="/checkout-review" disabled="false">
                            <span class="d-none d-sm-inline">Completar su orden</span>
                            <span class="d-inline d-sm-none">Siguiente</span><i class="czi-arrow-right fas fa-chevron-right mt-sm-0 ml-1"></i>
                        </button>
                      </form>
                    </div>
                  </div>
            </section>
           <app-aside [type]="type" [productos]="data" class="col-lg-4 pt-4 pt-lg-0"></app-aside>
        </div>
    </div>
</main>
<app-footer></app-footer>
