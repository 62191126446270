import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-orders',
  templateUrl: './account-orders.component.html',
  styleUrls: ['./account-orders.component.css']
})
export class AccountOrdersComponent implements OnInit {
  type:string='left';
  breadcrumbitems={
    type:'product',
    title:'Mis Orden',
    items:[{
      title:"Cuenta",
      routerlink:'/account-orders'
    },
    {
      title:'Mis Orden',
      routerLink:''
    }
    ]
  }
  constructor() { }

  ngOnInit(): void {
  }

}
