<app-navbar></app-navbar>
<main id="main" role="main">
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container py-4">
        <div class="row mb-4">
            <div class="col-sm-4 mb-2">
              <div class="bg-secondary h-100 p-4 text-center rounded"><span class="font-weight-medium text-heading mr-2">Enviado vía:</span>UPS Ground</div>
            </div>
            <div class="col-sm-4 mb-2">
              <div class="bg-secondary h-100 p-4 text-center rounded"><span class="font-weight-medium text-heading mr-2">Estado:</span>Orden de procesamiento</div>
            </div>
            <div class="col-sm-4 mb-2">
              <div class="bg-secondary h-100 p-4 text-center rounded"><span class="font-weight-medium text-heading mr-2">Fecha esperada:</span>15 de Mayo de 2021</div>
            </div>
        </div>

        <div class="card border-0 box-shadow">
            <div class="card-body">
              <div class="progress mb-3" style="height: 4px;">
                <div class="progress-bar bg-purple" role="progressbar" style="width: 36%" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="row pt-4">
                <div class="col-lg-3 col-sm-6">
                  <div class="media align-items-center mb-4">
                    <div class="rounded-circle border tracking-state-complete text-center" style="width: 60px; height: 60px; line-height: 54px;"><i class="fas fa-shopping-bag font-size-xl text-muted"></i></div>
                    <div class="media-body pl-3"><span class="badge badge-success badge-pill mb-1"><i class="fe-check mr-1"></i>Completado</span>
                      <h6 class="text-muted mb-0">Pedido realizado</h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <div class="media align-items-center mb-4">
                    <div class="rounded-circle border tracking-state-active text-center" style="width: 60px; height: 60px; line-height: 54px;"><i class="fas fa-cog font-size-xl "></i></div>
                    <div class="media-body pl-3"><span class="badge badge-purple badge-pill mb-1">En progreso</span>
                      <h6 class="text-purple mb-0">Orden de procesamiento</h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <div class="media align-items-center mb-4">
                    <div class="rounded-circle border text-center" style="width: 60px; height: 60px; line-height: 54px;"><i class="fas fa-gift font-size-xl"></i></div>
                    <div class="media-body pl-3"><span class="d-block text-muted font-size-ms mb-1">Tercer paso</span>
                      <h6 class="mb-0">Preparando</h6>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <div class="media align-items-center mb-4">
                    <div class="rounded-circle border text-center" style="width: 60px; height: 60px; line-height: 54px;"><i class="fas fa-box font-size-xl"></i></div>
                    <div class="media-body pl-3"><span class="d-block text-muted font-size-ms mb-1">Cuarto Paso</span>
                      <h6 class="mb-0">Producto enviado</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div class="d-sm-flex flex-wrap justify-content-between align-items-center text-center pt-4">
            <div class="custom-control custom-checkbox mt-2 mr-3">
              <input class="custom-control-input" type="checkbox" id="notify-me" checked="">
              <label class="custom-control-label" for="notify-me">Avisarme cuando se entregue el pedido</label>
            </div>
            <a class="btn btn-purple btn-sm mt-2" href="dashboard-orders.html">Ver detalles de la orden</a>
        </div>
    </div>
</main>
<app-footer></app-footer>
