import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-productos-details',
  templateUrl: './productos-details.component.html',
  styleUrls: ['./productos-details.component.css']
})
export class ProductosDetailsComponent implements OnInit {
    images: any[]= [];
    responsiveOptions:any[] = [
      {
          breakpoint: '1024px',
          numVisible: 5
      },
      {
          breakpoint: '768px',
          numVisible: 3
      },
      {
          breakpoint: '560px',
          numVisible: 1
      }
  ];
  constructor(private apiService:ApiService) { }

  ngOnInit(): void {
    this.apiService.getImages().then(images => {
      this.images = images
      console.log(this.images);
    });
  }

}
