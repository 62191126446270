import Swal from 'sweetalert2';
export const sweetAlertBasic = (data:any,type:any)=>{
    Swal.fire(
        data.title,
        data.message,
        type
    )
}
export const sweetAlertConfirm = ()=>{
    let response;
     Swal.fire({
        title: 'Estas seguro?',
        text: "No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          response = true;
        }else{
          response = false;
        }
      })

      return response;
}