<app-navbar></app-navbar>
<main>
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container pb-5 mb-2 mb-md-4 page-title-overlapis">
        <div class="row">
            <section class="col-lg-8">
                <app-steps [numberActive]="3"></app-steps>
                <h2 class="h6 pb-3 mb-2">Elija el método de envío</h2>
                <div class="table-responsive">
                    <table class="table table-hover font-size-sm border-bottom">
                      <thead>
                        <tr>
                          <th class="align-middle"></th>
                          <th class="align-middle">Método de envío</th>
                          <th class="align-middle">Tiempo de entrega</th>
                          <th class="align-middle">Tasa de tramitación</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="custom-control custom-radio mb-4">
                              <input class="custom-control-input" type="radio" id="courier" name="shipping-method" checked="">
                              <label class="custom-control-label" for="courier"></label>
                            </div>
                          </td>
                          <td class="align-middle"><span class="text-dark font-weight-medium">Courier</span><br><span class="text-muted">All addresses (default zone), United States &amp; Canada</span></td>
                          <td class="align-middle">2 - 4 days</td>
                          <td class="align-middle">$26.50</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-radio mb-4">
                              <input class="custom-control-input" type="radio" id="local" name="shipping-method">
                              <label class="custom-control-label" for="local"></label>
                            </div>
                          </td>
                          <td class="align-middle"><span class="text-dark font-weight-medium">Local Shipping</span><br><span class="text-muted">All addresses (default zone), United States &amp; Canada</span></td>
                          <td class="align-middle">up to one week</td>
                          <td class="align-middle">$10.00</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-radio mb-4">
                              <input class="custom-control-input" type="radio" id="flat" name="shipping-method">
                              <label class="custom-control-label" for="flat"></label>
                            </div>
                          </td>
                          <td class="align-middle"><span class="text-dark font-weight-medium">Flat Rate</span><br><span class="text-muted">All addresses (default zone)</span></td>
                          <td class="align-middle">5 - 7 days</td>
                          <td class="align-middle">$33.85</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-radio mb-4">
                              <input class="custom-control-input" type="radio" id="ups" name="shipping-method">
                              <label class="custom-control-label" for="ups"></label>
                            </div>
                          </td>
                          <td class="align-middle"><span class="text-dark font-weight-medium">UPS Ground Shipping</span><br><span class="text-muted">All addresses (default zone)</span></td>
                          <td class="align-middle">4 - 6 days</td>
                          <td class="align-middle">$18.00</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-radio mb-4">
                              <input class="custom-control-input" type="radio" id="pickup" name="shipping-method">
                              <label class="custom-control-label" for="pickup"></label>
                            </div>
                          </td>
                          <td class="align-middle"><span class="text-dark font-weight-medium">Local Pickup from store</span><br><span class="text-muted">All addresses (default zone)</span></td>
                          <td class="align-middle">—</td>
                          <td class="align-middle">$0.00</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-radio mb-4">
                              <input class="custom-control-input" type="radio" id="locker" name="shipping-method">
                              <label class="custom-control-label" for="locker"></label>
                            </div>
                          </td>
                          <td class="align-middle"><span class="text-dark font-weight-medium">Pick Up at Cartzilla Locker</span><br><span class="text-muted">All addresses (default zone), United States &amp; Canada</span></td>
                          <td class="align-middle">—</td>
                          <td class="align-middle">$9.99</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-radio mb-4">
                              <input class="custom-control-input" type="radio" id="global-export" name="shipping-method">
                              <label class="custom-control-label" for="global-export"></label>
                            </div>
                          </td>
                          <td class="align-middle"><span class="text-dark font-weight-medium">Cartzilla Global Export</span><br><span class="text-muted font-size-sm">All addresses (default zone), outside United States</span></td>
                          <td class="align-middle">3 - 4 days</td>
                          <td class="align-middle">$25.00</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-radio mb-4">
                              <input class="custom-control-input" type="radio" id="same-day" name="shipping-method">
                              <label class="custom-control-label" for="same-day"></label>
                            </div>
                          </td>
                          <td class="align-middle"><span class="text-dark font-weight-medium">Same-Day Delivery</span><br><span class="text-muted">Only United States</span></td>
                          <td class="align-middle">—</td>
                          <td class="align-middle">$34.00</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="custom-control custom-radio mb-4">
                              <input class="custom-control-input" type="radio" id="international" name="shipping-method">
                              <label class="custom-control-label" for="international"></label>
                            </div>
                          </td>
                          <td class="align-middle"><span class="text-dark font-weight-medium">International Shipping</span><br><span class="text-muted">All addresses (default zone)</span></td>
                          <td class="align-middle">up to 15 days</td>
                          <td class="align-middle">$27.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-none d-lg-flex pt-4">
                    <div class="w-50 pr-3">
                        <a class="btn btn-secondary btn-block" routerLink="/checkout-details">
                            <i class="czi-arrow-left fas fa-chevron-left mt-sm-0 mr-1"></i>
                            <span class="d-none d-sm-inline">Volver a Direcciones</span>
                            <span class="d-inline d-sm-none">Volver</span>
                        </a>
                    </div>
                    <div class="w-50 pl-2">
                        <a class="btn btn-purple btn-block" routerLink="/checkout-payment">
                            <span class="d-none d-sm-inline">Proceder al pago</span>
                            <span class="d-inline d-sm-none">Siguiente</span><i class="czi-arrow-right fas fa-chevron-right mt-sm-0 ml-1"></i>
                        </a>
                    </div>
                  </div>
            </section>
           <app-aside [type]="type" [productos]="data" class="col-lg-4 pt-4 pt-lg-0"></app-aside>
        </div>
    </div>
</main>
<app-footer></app-footer>
