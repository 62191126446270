<app-navbar></app-navbar>
<main id="main" role="main">
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container py-4">
        <h1 class="text-center text-purple">Catálogo</h1>
        <ng-container *ngIf="url">
            <pdf-viewer [src]="url"
            [render-text]="true"
            style="display: block;">
        </pdf-viewer>
        </ng-container>
    </div>
</main>
<app-footer></app-footer>
