import { Component, OnInit } from '@angular/core';
import {FormBuilder,FormGroup, Validators} from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
/////// externos //////
import Swal from 'sweetalert2';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  formNewsletter:FormGroup;
  categorias:any;
  constructor(
    private _formBuilder:FormBuilder,
    private apiService:ApiService,
  ) {
    this.formNewsletter = this._formBuilder.group({
      email: ['',[Validators.required,Validators.email]]
    })
   }

  ngOnInit(): void {
    this.getCategorias();
  }
  OnPostNewsletterUser(){
    const email = this.formNewsletter.value;
    this.apiService.postNewsletterUser(email).subscribe(result=>{
      if(result.res){
        Swal.fire({
          icon: 'success',
          title: result.message,
          showConfirmButton: false,
          timer: 3000
        })
      }else{
        Swal.fire({
          icon: 'error',
          title: result.message,
          showConfirmButton: false,
          timer: 3000
        })
      }
    })
  }
  
  OnToogleSidebarRes(resp:Boolean){
    console.log(resp);
  }

  getCategorias(){
    this.apiService.getCategorias().subscribe(result=>{
      //console.log(result);
      this.categorias=result;
    })
  }

 

}
