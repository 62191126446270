<app-navbar></app-navbar>
<main>
    <div class="container pb-5 mb-sm-4">
        <div class="pt-5">
          <div class="card py-3 mt-sm-3">
            <div class="card-body text-center">
              <h2 class="h4 pb-3">¡Gracias por su orden!</h2>
              <p class="font-size-sm mb-2">Su pedido ha sido realizado y será procesado lo antes posible.</p>
              <p class="font-size-sm mb-2">Asegúrese de anotar su número de pedido, que es<span class="font-weight-medium">34VB5540K83.</span></p>
              <p class="font-size-sm">En breve recibirá un correo electrónico con la confirmación de su pedido. <u>Tu puedes ahora:</u></p>
              <a class="btn btn-secondary mt-3 mr-3" routerLink="/categoria">Volver de compras</a>
              <a class="btn btn-primary mt-3" routerLink="/tracking"><i class="fas fa-map-marker-alt mr-2"></i>Rastreo de orden</a>
            </div>
          </div>
        </div>
      </div>
</main>
<app-footer></app-footer>