<app-navbar></app-navbar>
<main>
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container-fluid px-5 pb-5 mb-2 mb-md-4 page-title-overlapis">
        <div class="row">
            <aside class="col-lg-4">
               <app-sidebar></app-sidebar>
            </aside>
            <section class="col-lg-8">
              <div class="d-flex justify-content-center justify-content-sm-between align-items-center pt-2 pb-4 pb-sm-5">
                
                <div class="d-flex flex-wrap">
                  <div class="form-inline flex-nowrap mr-3 mr-sm-4 pb-3">
                    <label class="text-light opacity-75 text-nowrap mr-2 d-none d-sm-block" for="sorting">Ordenar por:</label>
                    <select class="form-control custom-select" id="sorting">
                      <option>Remendados</option>
                      <option>Menor - Mayor Precio</option>
                      <option>Mayor - Menor Precio</option>
                      <option>Los mejores evaluados</option>
             
                    </select><span class="font-size-sm text-light opacity-75 text-nowrap ml-2 d-none d-md-block">de 287 producto</span>
                  </div>
                </div>

                <div class="d-flex pb-3">
                  <a class="nav-link-style nav-link-light mr-3" href="#">
                    <i class="fas fa-chevron-left"></i>
                  </a>
                  <span class="font-size-md text-light">1 / 5</span>
                  <a class="nav-link-style nav-link-light ml-3" href="#">
                    <i class="fas fa-chevron-right"></i>
                  </a>
                </div>

                <div class="d-none d-sm-flex pb-3">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link nav-link-light active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true"><i class="fas fa-th-large"></i></a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link nav-link-light" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false"><i class="fas fa-list-ul"></i></a>
                    </li>
                  </ul>
                </div>

              </div>

              <div class="tab-content" id="pills-tabContent">

                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div class="row mx-n2" *ngIf="productos">
                    <div class="col-md-4 col-sm-6 px-2 mb-4" *ngFor="let product of productos; index as i">
                      <div class="card product-card">
                        <span *ngIf="product.descuentos!=0" class="badge badge-danger badge-shadow">Sale</span>
                        <button class="btn-wishlist btn-sm" type="button" matTooltip="Agregar mi lista de deseos">
                          <i class="czi-heart far fa-heart"></i>
                        </button>
                        <a class="card-img-top d-block overflow-hidden"  routerLink="/productos/{{product._id}}">
                          <img class="h-100 mh-200" src="{{product.imagenproductos}}" alt="Producto">
                        </a>
                        <div class="card-body py-2">
                          <a class="product-meta d-block font-size-xs pb-1"  routerLink="/productos/{{product._id}}">{{product.categoria}}</a>
                          <h3 class="product-title font-size-sm">
                            <a  routerLink="/productos/{{product._id}}">{{product.titulo}}</a>
                          </h3>
                          <div class="d-flex justify-content-between">
                            <div class="product-price">
                              <span class="text-accent">${{product.precioformateado}}</span>
                              <del class="font-size-sm text-muted" hidden>$38.<small>50</small></del>
                            </div>
                            <div class="star-rating">
                              <ng-rating-bar class="rating-card" [value]="product.ratings" [ratingCount]="5" [disabled]="true"  colorActive="#fea569" colorDefault="#8c98a4"></ng-rating-bar>
                            </div>
                          </div>
                        </div>
                        <div class="card-body card-body-hidden">
                          <div class="text-center pb-2">
                            <div class="custom-control custom-option custom-control-inline mb-2" *ngFor="let color of product.colores">
                              <input class="custom-control-input" type="radio" name="{{product._id}}" id="{{i}}{{color.colorcodigo}}" >
                              <label class="custom-option-label rounded-circle" for="{{i}}{{color.colorcodigo}}"><span class="custom-option-color rounded-circle" [ngStyle]="{'background-color': color.colorcodigo}"></span></label>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <select class="custom-select custom-select-sm mr-2 w-50">
                              <option *ngFor="let tallas of product.tallas">{{tallas}}</option>
                            </select>
                            <button class="btn btn-primary btn-sm" type="button" data-toggle="toast" data-target="#cart-toast"><i class="fas fa-cart-plus font-size-sm mr-1"></i>Agregar</button>
                          </div>
                          <div class="text-center">
                            <a class="nav-link-style font-size-ms" href="javascript::;" (click)="openDialog()">
                              <i class="far fa-eye align-middle mr-1"></i>
                              Vista rápida
                            </a>
                          </div>
                        </div>
                      </div>
                      <hr class="d-sm-none">
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div class="card product-card product-list">
                    <span class="badge badge-danger badge-shadow">Sale</span>
                    <button class="btn-wishlist" mat-mini-fab type="button" data-toggle="tooltip" data-placement="left" title="" data-original-title="Add to wishlist">
                      <mat-icon class="czi-heart">favorite_border</mat-icon>
                    </button>
                    <div class="d-sm-flex align-items-center">
                      <a class="product-list-thumb" href="shop-single-v1.html">
                        <img src="assets/image/storage/productos/3.png" alt="Product">
                      </a>
                      <div class="card-body py-2"><a class="product-meta d-block font-size-xs pb-1" href="#">Women’s T-shirt</a>
                        <h3 class="product-title font-size-base"><a href="shop-single-v1.html">Cotton Lace Blouse</a></h3>
                        <div class="d-flex justify-content-between">
                          <div class="product-price"><span class="text-accent">$28.<small>50</small></span>
                            <del class="font-size-sm text-muted">38.<small>50</small></del>
                          </div>
                          <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i><i class="sr-star czi-star"></i>
                          </div>
                        </div>
                        <div class="card-body card-body-hidden">
                          <div class="pb-2">
                            <div class="custom-control custom-option custom-control-inline mb-2">
                              <input class="custom-control-input" type="radio" name="color1" id="white" checked="">
                              <label class="custom-option-label rounded-circle" for="white"><span class="custom-option-color rounded-circle" style="background-color: #eaeaeb;"></span></label>
                            </div>
                            <div class="custom-control custom-option custom-control-inline mb-2">
                              <input class="custom-control-input" type="radio" name="color1" id="blue">
                              <label class="custom-option-label rounded-circle" for="blue"><span class="custom-option-color rounded-circle" style="background-color: #d1dceb;"></span></label>
                            </div>
                            <div class="custom-control custom-option custom-control-inline mb-2">
                              <input class="custom-control-input" type="radio" name="color1" id="yellow">
                              <label class="custom-option-label rounded-circle" for="yellow"><span class="custom-option-color rounded-circle" style="background-color: #f4e6a2;"></span></label>
                            </div>
                            <div class="custom-control custom-option custom-control-inline mb-2">
                              <input class="custom-control-input" type="radio" name="color1" id="pink">
                              <label class="custom-option-label rounded-circle" for="pink"><span class="custom-option-color rounded-circle" style="background-color: #f3dcff;"></span></label>
                            </div>
                          </div>
                          <div class="d-flex mb-2">
                            <select class="custom-select custom-select-sm mr-2" style="max-width: 6rem;">
                              <option>XS</option>
                              <option>S</option>
                              <option>M</option>
                              <option>L</option>
                              <option>XL</option>
                            </select>
                            <button class="btn btn-primary btn-sm" type="button" data-toggle="toast" data-target="#cart-toast"><i class="czi-cart font-size-sm mr-1"></i>Add to Cart</button>
                          </div>
                          <div class="text-left"><a class="nav-link-style font-size-ms" href="#quick-view" data-toggle="modal"><i class="czi-eye align-middle mr-1"></i>Quick view</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </section>
        </div>
    </div>
</main>
<app-footer></app-footer>

