import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CatalogoComponent } from './pages/catalogo/catalogo.component';
import { ContactoComponent} from './pages/contacto/contacto.component';
import { CategoriaComponent} from './pages/categoria/categoria.component';
import { ProductosDetallesComponent } from './pages/productos-detalles/productos-detalles.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { TrackingDetailsComponent } from './pages/tracking-details/tracking-details.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutDetailsComponent } from './pages/checkout-details/checkout-details.component';
import { CheckoutShippingComponent } from './pages/checkout-shipping/checkout-shipping.component';
import { CheckoutPaymentComponent } from './pages/checkout-payment/checkout-payment.component';
import { CheckoutReviewComponent } from './pages/checkout-review/checkout-review.component';
import { CheckoutCompleteComponent } from './pages/checkout-complete/checkout-complete.component';
import { AccountWishlistComponent } from './account/pages/account-wishlist/account-wishlist.component';
import { AccountOrdersComponent } from './account/pages/account-orders/account-orders.component';
import { AccountTicketsComponent } from './account/pages/account-tickets/account-tickets.component';
import { AccountProfileComponent } from './account/pages/account-profile/account-profile.component';
import { AccountAddressComponent } from './account/pages/account-address/account-address.component';
import { AccountPaymentComponent } from './account/pages/account-payment/account-payment.component';
import { AccountSegurityComponent } from './account/pages/account-segurity/account-segurity.component';
import { CatalogoDetailsComponent } from './pages/catalogo-details/catalogo-details.component';
import { AcercaComponent } from './pages/acerca/acerca.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', component:HomeComponent},
  { path: 'acerca', component:AcercaComponent},
  { path: 'catalogo', component:CatalogoComponent},
  { path: 'catalogo/:id', component:CatalogoDetailsComponent},
  { path: 'contacto', component:ContactoComponent},
  { path: 'categoria', component:CategoriaComponent},
  { path: 'productos/:id', component:ProductosDetallesComponent},
  { path: 'tracking', component:TrackingComponent},
  { path: 'tracking/:id', component:TrackingDetailsComponent},
  { path: 'cart', component:CartComponent},
  { path: 'checkout-details', component:CheckoutDetailsComponent},
  { path: 'checkout-shipping', component:CheckoutShippingComponent},
  { path: 'checkout-payment', component:CheckoutPaymentComponent },
  { path: 'checkout-review', component:CheckoutReviewComponent },
  { path: 'checkout-complete', component:CheckoutCompleteComponent },
  { path: 'account-orders', component:AccountOrdersComponent, canActivate:[AuthGuard] },
  { path: 'account-profile', component:AccountProfileComponent, canActivate:[AuthGuard] },
  { path: 'account-wishlist', component:AccountWishlistComponent, canActivate:[AuthGuard] },
  { path: 'account-tickets', component:AccountTicketsComponent, canActivate:[AuthGuard] },
  { path: 'account-address', component:AccountAddressComponent, canActivate:[AuthGuard] },
  { path: 'account-payment', component:AccountPaymentComponent, canActivate:[AuthGuard] },
  { path: 'account-security', component:AccountSegurityComponent, canActivate:[AuthGuard] },
  { path: 'admin',canLoad:[AuthGuard] ,loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
