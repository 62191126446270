import { Component, OnInit,ChangeDetectionStrategy } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';
import {ModalComponent} from '../../components/modal/modal.component';
///////////////////// SERVICE /////////////
import { ApiService } from '../../services/api.service';
@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriaComponent implements OnInit {
  breadcrumbitems={
    type:'product',
    title:'Todos los Productos',
    items:[{
      title:"Todos los Productos",
      routerlink:''
    }]
  }
  productos:any;
  panelOpenState:Boolean= false;
  rangeValues: number[] = [20,80];
  selectedCategories: any[] = ['Technology', 'Sports'];
  categories: any[] = [{name: 'Globos Metalicos', key: 'G'}, {name: 'Globos lates', key: 'M'}, {name: 'Globos con letras', key: 'P'}, {name: 'Globos con numeros', key: 'F'}];
  sizes: any[] = [{name: '14', key: '14'}, {name: '15', key: '15'}, {name: '16', key: '16'}, {name: '17', key: '17'}];
  items = Array.from({length: 100000}).map((_, i) => `Item #${i}`);
  constructor(
    public dialog: MatDialog,
    private apiService:ApiService
  ) { }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    //this.selectedCategories = this.categories.slice(1,3);
    this.getProductos();
  }
  getProductos(){
    this.apiService.getProductos().subscribe(result=>{
      console.log(result);
      this.productos = result;
    })
  }
  openDialog(productos?:any){
    const config={
      height: '1000px',
      width: '1140px',
      data:{
        message:productos ? 'Nombre del producto' : 'Nombre del producto',
        content:productos,
      }
    }

    const dialogRef = this.dialog.open(ModalComponent,config);

    dialogRef.afterClosed().pipe(debounceTime(5000)).subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }

}
