import { Component, OnInit ,Input,Output, EventEmitter } from '@angular/core';
import { Router,ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  ruta$:Observable<string> | string="";
  @Input() qualityCart:number = 0;
  @Input() totalCart:number = 0;
  @Output() sidebarToogle=new EventEmitter<boolean>(false);

  toolbarToogle=true;
  constructor(
    private rutaActiva: ActivatedRoute,
    private router: Router,
  ) { 
    
  }

  ngOnInit(): void {
    this.ruta$=this.router.url;
  }
  OnSidebarToggle(){
    this.sidebarToogle.emit(true);
    this.toolbarToogle=false;
  }

}
