import { Component, OnInit } from '@angular/core';
import {FormGroup,FormBuilder,Validators} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-checkout-review',
  templateUrl: './checkout-review.component.html',
  styleUrls: ['./checkout-review.component.css']
})
export class CheckoutReviewComponent implements OnInit {
  type:string="right";
  token:string | null ='';
  data:any;
  items:any;
  breadcrumbitems={
    type:'product',
    title:'Revisa',
    items:[{
      title:"Compras",
      routerlink:'/categoria'
    },
    {
      title:'Revisa',
      routerLink:''
    }
    ]
  }
  constructor(
    private formBuilder:FormBuilder,
    private apiService:ApiService,
  ) { }

  ngOnInit(): void {
    if (typeof(Storage) !== "undefined") {
      
      this.token=localStorage.getItem("token1");
   
      if(this.token!=null){
        this.getTokenCart(this.token);
      }
      if(localStorage.getItem("direction")!=null){
        const getdirection:any=localStorage.getItem("direction");
      }
    
    }
    this.getLastOrden();
  }

  getTokenCart(token:string){
    this.apiService.getCart(token).subscribe(result=>{
      this.data = result;
      console.log(this.data);
    });
  }

  getLastOrden(){
    const token:string | null=localStorage.getItem('token-webpay');
    this.apiService.getLastOrden(token).subscribe(result=>{
      this.items = result.items[0];
      console.log(this.items);
    })
  }

}
