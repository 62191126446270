import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
////////////////// decode token //////////////////
import jwt_decode from 'jwt-decode';
//////////////////////// interfaces /////////////
import { User,Roles } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  api: string = 'http://localhost:3443';
  private role = new BehaviorSubject<Roles>(null);
  email$:Observable<any> | undefined
  constructor(
    private http: HttpClient,
    private router: Router
    ) { }
  
  get isAdmin$ ():Observable<string | null>{
    return this.role.asObservable();
  }
  singIn(Data:any){
    const path=`${this.api}/sing-in`;
    return this.http.post<any>(path,Data);
  }
  singUp(Data:any){
    const path=`${this.api}/sing-up`;
    return this.http.post<any>(path,Data);
  }
  loggedIn():Boolean{
    return !!localStorage.getItem('user-token');
  }
  saveToken(user:any){
    localStorage.setItem('user-token',user.token);
    localStorage.setItem('user',JSON.stringify(user));
    this.role.next(user.user.role);
  }
  checkToken(user:any){
    return <any>localStorage.getItem('user-token');
  }
  getUserInfo(){
    const l: any = localStorage.getItem('user');
    const u: any = JSON.parse(l);
    const user = u.user;
    return user;
  }
  getToken():any{
    return <any>localStorage.getItem('user-token');
  }
  forgotPassword(email:any){
    const path=`${this.api}/forgot-password`;
    return this.http.post<any>(path,email);
  }
  logout(){
    localStorage.removeItem('user-token');
    this.router.navigate(['/']);
  }
  decodeToken(token: string): any {
    return jwt_decode(token);
  }
  checkEmail(email:string){
    const path=`${this.api}/check-email/${email}`;
    return this.http.get<any>(path);
  }

}
