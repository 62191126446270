<app-navbar></app-navbar>
<main id="main" role="main">
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container py-4">
        <h1 class="text-center text-purple">Catálogo</h1>
        <div class="row py-4" *ngIf="catalogo$ | async as catalogo">
            <div class="col-lg-4 py-3" *ngFor="let cata of catalogo">
                <div class="d-block">
                    <img class="img-fluid rounded" src="{{cata.portada}}" />
                    <h1 class="text-center text-purple">{{cata.titulo}}</h1>
                </div>
                <div class="d-flex justify-content-center">
                    <a class="btn btn-fill btn-purple mr-3" routerLink="/catalogo/{{cata._id}}">Ver Online</a>
                    <a class="btn btn-fill btn-secondary" href="{{cata.pdf}}" download="">Descargar</a>
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>
