<div class="cz-handheld-toolbar" *ngIf="toolbarToogle">
    <div class="d-table table-fixed w-100"  *ngIf="ruta$ as ruta">
      <a  *ngIf="ruta=='/categoria'" class="d-table-cell cz-handheld-toolbar-item" href="javascript::" (click)="OnSidebarToggle()" data-toggle="sidebar">
        <span class="cz-handheld-toolbar-icon mt-1">
          <i class="fas fa-sliders-h"></i>
        </span>
        <span class="cz-handheld-toolbar-label">Filtrar</span>
      </a>
      <a class="d-table-cell cz-handheld-toolbar-item" routerLink="/account-wishlist">
        <span class="cz-handheld-toolbar-icon mt-1">
          <i class="far fa-heart"></i>
        </span>
        <span class="cz-handheld-toolbar-label">Lista de deseos</span>
      </a>
      <a class="d-table-cell cz-handheld-toolbar-item collapsed" href="#navbarCollapse" data-toggle="collapse" onclick="window.scrollTo(0, 0)" aria-expanded="false">
        <span class="cz-handheld-toolbar-icon mt-1">
          <i class="fas fa-bars"></i>
        </span>
        <span class="cz-handheld-toolbar-label">Menu</span>
      </a>
      <a class="d-table-cell cz-handheld-toolbar-item" routerLink="/cart">
        <span class="cz-handheld-toolbar-icon mt-1">
          <i class="fas fa-shopping-cart"></i>
          <span class="badge badge-purple badge-pill ml-1">{{qualityCart}}</span>
        </span>
        <span class="cz-handheld-toolbar-label">${{totalCart}}</span>
      </a>
    </div>
  </div>