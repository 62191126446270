import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  token:any;
  numberItems: number = 0;
  amountCart: number = 0;
  data:any;
  breadcrumbitems={
    type:'product',
    title:'Tu carrito',
    items:[{
      title:"Compras",
      routerlink:'/categoria'
    },
    {
      title:'Carro',
      routerLink:''
    }
    ]
  }
  constructor(private apiService:ApiService) {

   }

  ngOnInit(): void {
    if (typeof(Storage) !== "undefined") {
      
      this.token=localStorage.getItem("token1");
   
      if(this.token!=null){
        this.getTokenCart(this.token);
      }
    
    } 
  }

  
  getTokenCart(token:any){
    this.apiService.getCart(token).subscribe(result=>{
      this.data = result;
      this.numberItems = result.cantidad;
      this.amountCart = result.totalformateado;
      if(result.cantidad==0){
        localStorage.removeItem('token');
        localStorage.removeItem('token1');
        this.data=undefined;
      } 
    })
  }
  deleteItems(id:string){
    const datos={
      token:this.token,
      id:id
    };
    this.apiService.deleteCartItems(datos).subscribe(result=>{
      if(result.res){
        localStorage.setItem('token',result.token);
        this.token=result.token;
        this.getTokenCart(this.token);
      }
    });
  }
  deleteAllItems(){
    const token=this.token;
    this.apiService.deleteCartAllItems(token).subscribe(result=>{
      if(result.res){
        localStorage.removeItem('token');
        localStorage.removeItem('token1');
        this.data=undefined; 
        this.token=null;
      }
    })

  }

  changeQualityItem(id:string,cantidad:any){
   
    const data={
      token:this.token,
      id:id,
      cantidad:cantidad.target.value,
    }
    
    this.apiService.putCartQualityItem(data).subscribe(result=>{
      if(result.res){
        localStorage.setItem('token1',result.token);
        this.token=result.token
        this.getTokenCart(this.token);
      }
    })

  }

}
