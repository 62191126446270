import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularEditorComponent } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-catalogo-details',
  templateUrl: './catalogo-details.component.html',
  styleUrls: ['./catalogo-details.component.css']
})
export class CatalogoDetailsComponent implements OnInit {
  id:any;
  url:any;
  catalogo:any;
  breadcrumbitems={
    type:'normal',
    title:'Catálogo',
    items:[{
      title:"Catálogo",
      routerlink:''
    }]
  }
  constructor(
    private apiService:ApiService,
    private rutaActiva:ActivatedRoute,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    this.rutaActiva.paramMap.subscribe(params =>{
      this.id=params.get('id');
      this.getCatalogo(this.id);
    })
  }
  getCatalogo(id:string){
    this.apiService.getCatalogoDetails(id).subscribe(result=>{
      console.log(result);
      this.catalogo = result;
      const path: string = (result.pdf).toString();
      console.log(path);
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      //this.url = this.sanitizer.bypassSecurityTrustUrl(path);
      this.url = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
    })
  }

}
