import { Component, OnInit } from '@angular/core';
import {FormGroup,FormBuilder,Validators} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
////////////////// externos //////////
import Swal from 'sweetalert2'
////// service ////// 
import {ApiService} from '../../services/api.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  formContacto:FormGroup;
  breadcrumbitems={
    type:'normal',
    title:'Contacto',
    items:[{
      title:"Contacto",
      routerlink:''
    }]
  }
  constructor(
    private _formBuilder:FormBuilder,
    private _apiService:ApiService
    ) {
    this.formContacto=this._formBuilder.group({
      nombre:['',Validators.required],
      email:['',[Validators.required,Validators.email]],
      telefono:['',Validators.required],
      asunto:['',Validators.required],
      mensaje:['',Validators.required],
    })
  
   }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
  onSendMail(){
    const value=this.formContacto.value;

    this._apiService.PostContacto(value).subscribe(result=>{
      console.log(result);
      if(result.res){
        Swal.fire(
          'Mensaje Enviado!',
          'Sera contactado a la brevedad',
          'success'
        )
      }else{
        Swal.fire(
          'Error!',
          'error al enviar mensaje, intente de nuevo',
          'error'
        )
      }
    })
  }
}
