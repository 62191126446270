import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Observable } from 'rxjs';
import { Catalogo } from '../../interfaces/catalogo';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css']
})
export class CatalogoComponent implements OnInit {
  breadcrumbitems={
    type:'normal',
    title:'Catálogo',
    items:[{
      title:"Catálogo",
      routerlink:''
    }]
  }
  catalogo$:Observable<Catalogo[]> | undefined;
  constructor(private apiService:ApiService) { }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.getCatalogo();
  }
  getCatalogo(){
    this.catalogo$ = this.apiService.getCatalogo();
  }

}
