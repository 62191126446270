<aside *ngIf="type=='right'">
    <div class="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto" *ngIf="productos;else showTotal">
      <div class="widget mb-3">
        <h2 class="widget-title text-center">Resumen de pedido</h2>
    
          <div class="media align-items-center pb-2 border-bottom" *ngFor="let items of productos.items">
            <a class="d-block mr-2" routerLink="{{items.url}}">
                <img src="{{items.img}}" alt="Product" width="64">
            </a>
            <div class="media-body">
              <h6 class="widget-product-title"><a routerLink="{{items.url}}">{{items.titulo}}</a></h6>
              <div class="widget-product-meta"><span class="text-accent mr-2">${{items.preciounitarioformateado}}</span><span class="text-muted">x {{items.cantidad}}</span></div>
            </div>
          </div>
     
      </div>
      <ul class="list-unstyled font-size-sm pb-2 border-bottom">
        <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Subtotal:</span><span class="text-right">${{productos.totalformateado}}</span></li>
        <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Envío:</span><span class="text-right">—</span></li>
        <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Impuestos:</span><span class="text-right">$9.<small>50</small></span></li>
        <li class="d-flex justify-content-between align-items-center"><span class="mr-2">Descuento:</span><span class="text-right">—</span></li>
      </ul>
      <h3 class="font-weight-normal text-center my-4">${{productos.totalformateado}}</h3>
      <form class="needs-validation" method="post" novalidate="">
        <div class="form-group">
          <input class="form-control" type="text" placeholder="Código promocional">
          <div class="invalid-feedback">
            Proporcione el código de promoción.
          </div>
        </div>
        <button class="btn btn-outline-accent btn-block" type="submit">Aplicar código promocional</button>
      </form>
    </div>
    <ng-template #showTotal>
      <div class="bg-white rounded-3 shadow-lg p-4 ms-lg-auto">
        <div class="py-2 px-xl-2">
          <h2 class="h6 text-center mb-4">Order summary</h2>
          <ul class="list-unstyled fs-sm pb-2 border-bottom">
            <li class="d-flex justify-content-between align-items-center"><span class="me-2">Subtotal:</span><span class="text-end">$265.<small>00</small></span></li>
            <li class="d-flex justify-content-between align-items-center"><span class="me-2">Shipping:</span><span class="text-end">—</span></li>
            <li class="d-flex justify-content-between align-items-center"><span class="me-2">Taxes:</span><span class="text-end">$9.<small>50</small></span></li>
            <li class="d-flex justify-content-between align-items-center"><span class="me-2">Discount:</span><span class="text-end">—</span></li>
          </ul>
          <h3 class="fw-normal text-center my-4">$274.<small>50</small></h3>
          <form class="needs-validation" method="post" novalidate="">
            <div class="mb-3">
              <input class="form-control" type="text" placeholder="Promo code" required="">
              <div class="invalid-feedback">Please provide promo code.</div>
            </div>
            <button class="btn btn-outline-accent d-block w-100" type="submit">Apply promo code</button>
          </form>
        </div>
      </div>
    </ng-template>
</aside>


<aside *ngIf="type=='left'">
  <div class="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
    <div class="px-4 mb-4">
      <div class="media align-items-center">
        <div class="img-thumbnail rounded-circle position-relative" style="width: 6.375rem;">
          <span class="badge badge-purple" data-toggle="tooltip" title="" data-original-title="Reward points">384</span>
          <img class="rounded-circle" src="{{userInfo.avatar}}" alt="Susan Gardner">
        </div>
        <div class="media-body pl-3">
          <h3 class="font-size-base mb-0">{{userInfo.nombre}} {{userInfo.apellido}}</h3><span class="text-accent font-size-sm">{{userInfo.email}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-secondary px-4 py-3">
    <h3 class="font-size-sm mb-0 text-muted">Dashboard</h3>
  </div>
  <ul class="list-unstyled mb-0">
    <li class="border-bottom mb-0">
      <a class="nav-link-style d-flex align-items-center px-4 py-3 active" routerLink="/account-orders">
        <i class="czi-bag fas fa-shopping-bag opacity-60 mr-2"></i>Orden
        <span class="font-size-sm text-muted ml-auto">1</span>
      </a>
    </li>
    <li class="border-bottom mb-0">
      <a class="nav-link-style d-flex align-items-center px-4 py-3" routerLink="/account-wishlist">
        <i class="czi-heart far fa-heart opacity-60 mr-2"></i>Lista de deseos<span class="font-size-sm text-muted ml-auto">{{numberItemsWishlist}}</span>
      </a>
    </li>
    <li class="mb-0">
      <a class="nav-link-style d-flex align-items-center px-4 py-3" routerLink="/account-tickets">
        <i class="czi-help far fa-life-ring opacity-60 mr-2"></i>Tickets de soporte
        <span class="font-size-sm text-muted ml-auto">1</span>
      </a>
    </li>
  </ul>
  <div class="bg-secondary px-4 py-3">
    <h3 class="font-size-sm mb-0 text-muted">Configuraciones de la cuenta</h3>
  </div>
  <ul class="list-unstyled mb-0">
    <li class="border-bottom mb-0">
      <a class="nav-link-style d-flex align-items-center px-4 py-3" routerLink="/account-profile">
        <i class="czi-user far fa-user opacity-60 mr-2"></i>Información de perfil
      </a>
    </li>
    <li class="border-bottom mb-0">
      <a class="nav-link-style d-flex align-items-center px-4 py-3" routerLink="/account-security">
        <i class="czi-user fas fa-shield-alt  opacity-60 mr-2"></i>Seguridad y Contraseña
      </a>
    </li>
    <li class="border-bottom mb-0">
      <a class="nav-link-style d-flex align-items-center px-4 py-3" routerLink="/account-address">
        <i class="czi-location fas fa-map-marker-alt opacity-60 mr-2"></i>Direcciones
      </a>
    </li>
    <li class="mb-0" hidden>
      <a class="nav-link-style d-flex align-items-center px-4 py-3" routerLink="/account-payment">
        <i class="czi-card far fa-credit-card opacity-60 mr-2"></i>Metodos de pago
      </a>
    </li>
    <li class="d-lg-none border-top mb-0">
      <a class="nav-link-style d-flex align-items-center px-4 py-3" href="javascript:void(0);" (click)="authService.logout()">
        <i class="czi-sign-out fas fa-sign-in-alt opacity-60 mr-2"></i>Cerrar sesion
      </a>
    </li>
  </ul>
</aside>



