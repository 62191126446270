<header class="box-shadow-sm">
  <!-- Topbar-->
  <div class="topbar topbar-dark bg-dark">
    <div class="container">
      <div>
        
        <div class="topbar-text text-nowrap d-none d-md-inline-block border-left border-light pl-3 ml-3">
          <span class="text-muted mr-1">Disponible 24/7 </span>
          <a class="topbar-link" href="tel:+56978956121">(+56) 978 956 121</a>
        </div>
      </div>
      <div class="topbar-text dropdown d-md-none ml-auto">
        <a class="topbar-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Lista de deseos / Rastreo</a>
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
          <li>
            <a class="dropdown-item" routerLink="/account-wishlist">
              <i class="czi-heart far fa-heart text-muted mr-2"></i>Lista de deseos <span *ngIf="authService.loggedIn()">({{numberItemsWishlist}})</span>
            </a>
          </li>

          <li>
            <a class="dropdown-item" routerLink="/tracking">
              <i class="czi-location fa-map-marker-alt text-muted mr-2"></i>Rastreo de orden
            </a>
          </li>
        </ul>
      </div>
      <div class="d-none d-md-block ml-3 text-nowrap">
        <a class="topbar-link d-none d-md-inline-block" routerLink="/account-wishlist">
          <i class="czi-heart far fa-heart mr-2 mt-n1"></i>Lista de deseos <span *ngIf="authService.loggedIn()">({{numberItemsWishlist}})</span>
        </a>

        <a class="topbar-link ml-3 border-left border-light pl-3 d-none d-md-inline-block" routerLink="/tracking">
          <i class="czi-location fas fa-map-marker-alt mr-2 mt-n1"></i>Rastreo de orden
        </a>
      </div>
    </div>
  </div>
  <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page.-->
  <div class="navbar-sticky bg-light">
    <div class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <a class="navbar-brand d-none d-sm-block mr-3 flex-shrink-0" routerLink="/" style="min-width: 7rem;">
          <img src="assets/image/logo/selinelogo.png" alt="Seline Store" width="200">
        </a>
        <a class="navbar-brand d-sm-none mr-2" routerLink="/" style="min-width: 4.625rem;">
          <img src="assets/image/logo/selinelogo.png" alt="Seline Store" width="74">
        </a>
        <!-- Search-->
        <div class="input-group-overlay d-none d-lg-block mx-4">
          <div class="input-group-prepend-overlay">
            <span class="input-group-text">
              <mat-icon class="czi-search">search</mat-icon>
            </span>
          </div>
          <input class="form-control prepended-form-control appended-form-control" type="text" placeholder="Buscar productos">
         
        </div>
        <!-- Toolbar-->
        <div class="navbar-toolbar d-flex flex-shrink-0 align-items-center">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"><span class="navbar-toggler-icon"></span></button>
          <ng-container *ngIf="!authService.loggedIn();else showAvatar">
            <a class="navbar-tool ml-1 ml-lg-0 mr-n1 mr-lg-2" routerLink="/login">
              <div class="navbar-tool-icon-box">
                <mat-icon class="navbar-tool-icon czi-user">person</mat-icon>
              </div>
              <div class="navbar-tool-text ml-n3"><small>Hola, inicia sesión</small>Mi cuenta</div>
            </a>
          </ng-container>
          <ng-template #showAvatar>
            <div class="navbar-tool dropdown ms-2">
              <a class="navbar-tool-icon-box border dropdown-toggle" routerLink="/account-profile">
              <img *ngIf="userInfo.avatar;else showAva" class="rounded-circle w-100" src="{{userInfo.avatar}}" alt="Seline Store" width="32"></a>
              <ng-template #showAva>
                <app-avatar [LabelAvatar]="iniciales"></app-avatar>
              </ng-template>
              <a class="navbar-tool-text ms-n1" routerLink="/account-profile"><small class="text-capitalize">{{userInfo.nombre}} {{userInfo.apellido}}</small>$1,375.00</a>
              <div class="dropdown-menu dropdown-menu-end">
                <div style="min-width: 14rem;">
                  <h6 class="dropdown-header">Cuenta</h6>
                  <a class="dropdown-item d-flex align-items-center" routerLink="/account-profile">
                    <i class="ci-settings fas fa-user-cog opacity-60 mr-2"></i>Configuracíon
                  </a>
                  <a class="dropdown-item d-flex align-items-center" routerLink="/account-orders">
                    <i class="ci-basket fas fa-shopping-basket opacity-60 mr-2"></i>Compras
                  </a>
                  <a class="dropdown-item d-flex align-items-center"routerLink="/account-wishlist">
                    <i class="ci- far fa-heart opacity-60 mr-2"></i>Lista de deseos
                    <span class="badge badge-purple fs-xs  ml-auto">{{numberItemsWishlist}}</span>
                  </a>
                  <ng-container *ngIf="userInfo.role == 'admin'">
                    <div class="dropdown-divider"></div>
                    <h6 class="dropdown-header">Admin</h6>
                    <a class="dropdown-item d-flex align-items-center" routerLink="/admin">
                      <i class="ci-cloud-upload fas fa-sliders-h opacity-60 mr-2"></i>Dashboard
                    </a>
                    <a class="dropdown-item d-flex align-items-center" routerLink="/admin/productos">
                      <i class="ci-package fas fa-box-open opacity-60 mr-2"></i>Productos<span class="badge badge-purple fs-xs ml-auto">5</span>
                    </a>
                    <a class="dropdown-item d-flex align-items-center" routerLink="/admin/post-product">
                      <i class="ci-cloud-upload fas fa-cloud-upload-alt opacity-60 mr-2"></i>Agregar nuevo productos
                    </a>
                    <a class="dropdown-item d-flex align-items-center" routerLink="/admin/orden">
                      <i class="ci-currency-exchange fas fa-dollar-sign opacity-60 mr-2"></i>Orden
                    </a>
                  </ng-container>
                    
                 
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item d-flex align-items-center" (click)="authService.logout()" >
                    <i class="ci-sign-out fas fa-sign-out-alt opacity-60 mr-2"></i>Cerrar sesion
                  </a>
                </div>
              </div>
            </div>
            
          </ng-template>

          <div class="navbar-tool dropdown ml-3">
            <a class="navbar-tool-icon-box bg-secondary dropdown-toggle" routerLink="/cart">
              <span class="navbar-tool-label">{{numberItems}}</span>
              <mat-icon class="navbar-tool-icon czi-cart" aria-hidden="false">shopping_cart</mat-icon>
            </a>
            <a class="navbar-tool-text" routerLink="/cart">
              <small>Mi carrito</small>${{amountCart}}</a>
            <!-- Cart dropdown-->
            <div class="dropdown-menu dropdown-menu-right" style="width: 20rem;">
              <div class="widget widget-cart px-3 pt-2 pb-3" *ngIf="numberItems > 0;else showCartEmty">
                <div class="scroll-ui-default" style="height: 15rem;" data-simplebar="init" data-simplebar-auto-hide="false" *ngIf="data">
                    <div class="widget-cart-item pb-2 border-bottom" *ngFor="let item of data.items">
                      <button class="close text-danger" type="button" aria-label="Remove" (click)="deleteItems(item.id)"><span aria-hidden="true">×</span></button>
                      <div class="media align-items-center">
                        <a class="d-block mr-2" routerLink="{{item.url}}">
                          <img src="{{item.img}}" alt="Product" width="64">
                        </a>
                        <div class="media-body">
                          <h6 class="widget-product-title"><a routerLink="{{item.url}}">{{item.titulo}}</a></h6>
                          <div class="widget-product-meta"><span class="text-accent mr-2">${{item.preciounitarioformateado}}</span><span class="text-muted">x {{item.cantidad}}</span></div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between align-items-center py-3">
                  <div class="font-size-sm mr-2 py-2">
                    <span class="text-muted">Subtotal:</span>
                    <span class="text-accent font-size-base ml-1">${{amountCart}}</span>
                  </div>
                  <a class="btn btn-outline-secondary btn-sm" routerLink="/cart">Expandir carrito<i class="czi-arrow-right fas fa-chevron-right ml-1 mr-n1"></i></a>
                </div>
                  <a class="btn btn-purple btn-sm btn-block" routerLink="/checkout-details"><i class="czi-card far fa-credit-card mr-2 font-size-base align-middle"></i>Revisar</a>
              </div>
              <ng-template #showCartEmty>
                <div class="widget widget-cart px-3 pt-2 pb-3">
                  <figure class="max-w-9rem mx-auto mb-3">
                    <img class="img-fluid" src="assets/image/svg/empty-cart.svg" alt="SVG">
                  </figure>
                  <span class="d-block text-center">Tu carrito esta vacío</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar navbar-expand-lg navbar-light navbar-stuck-menu mt-n2 pt-0 pb-2">
      <div class="container">
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <!-- Search-->
          <div class="input-group-overlay d-lg-none my-3">
            <div class="input-group-prepend-overlay">
              <span class="input-group-text">
                <mat-icon class="czi-search">search</mat-icon>
              </span>
            </div>
            <input class="form-control prepended-form-control" type="text" placeholder="Buscar productos">
          </div>
          <!-- Departments menu-->
          <ul class="navbar-nav mega-nav pr-lg-2 mr-lg-2">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle pl-0" href="#" data-toggle="dropdown"><mat-icon class="czi-menu align-middle mt-n1 mr-2">widgets</mat-icon>Todas las categorias</a>
              <ul class="dropdown-menu">
                <li class="dropdown mega-dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown"><i class="czi-laptop opacity-60 font-size-lg mt-n1 mr-2"></i>Computers &amp; Accessories</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-flex flex-wrap flex-md-nowrap px-2">
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Computers</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Laptops &amp; Tablets</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Desktop Computers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Computer External Components</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Computer Internal Components</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Networking Products (NAS)</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Single Board Computers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Desktop Barebones</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Accessories</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Monitors</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Bags, Cases &amp; Sleeves</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Batteries</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Charges &amp; Adapters</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Cooling Pads</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Mounts</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Replacement Screens</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Security Locks</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Stands</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column d-none d-lg-block py-4 text-center"><a class="d-block mb-2" href="#"><img src="https://cartzilla.createx.studio/img/shop/cart/01.jpg" alt="Computers &amp; Accessories"></a>
                        <div class="font-size-sm mb-3">Starting from <span class="font-weight-medium">$149.<small>80</small></span></div><a class="btn btn-primary btn-shadow btn-sm" href="#">See offers<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="dropdown mega-dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown"><i class="czi-mobile opacity-60 font-size-lg mt-n1 mr-2"></i>Smartphones &amp; Tablets</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-flex flex-wrap flex-md-nowrap px-2">
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links mb-3">
                          <h6 class="font-size-base mb-3">Smartphones</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Apple iPhone</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Android Smartphones</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Phablets</a></li>
                          </ul>
                        </div>
                        <div class="widget widget-links">
                          <h6 class="font-size-base">Tablets</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Apple iPad</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Android Tablets</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Tablets with Keyboard</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Accessories</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Accessory Kits</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Batteries &amp; Battery Packs</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Cables</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Car Accessories</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Charges &amp; Power Adapters</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">FM Transmitters</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Lens Attachments</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Mounts &amp; Stands</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Repair Kits</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Replacement Parts</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Styluses</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column d-none d-lg-block py-4 text-center"><a class="d-block mb-2" href="#"><img src="https://cartzilla.createx.studio/img/shop/cart/01.jpg" alt="Smartphones &amp; Tablets"></a>
                        <div class="font-size-sm mb-3">Starting from <span class="font-weight-medium">$127.<small>00</small></span></div><a class="btn btn-primary btn-shadow btn-sm" href="#">See offers<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="dropdown mega-dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown"><i class="czi-monitor opacity-60 font-size-lg mt-n1 mr-2"></i>TV, Video &amp; Audio</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-flex flex-wrap flex-md-nowrap px-2">
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Television &amp; Video</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">TV Sets</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Home Theater Systems</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">DVD Players &amp; Recorders</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Blue-ray Players &amp; Recorders</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">HD DVD Players &amp; Recorders</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">DVD-VCR Combos</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">DTV Converters</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">AV Receivers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">AV Amplifiers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Projectors</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Projection Screens</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Satelite Television</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">TV-DTD Combos</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Sound Systems</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Home Cinema Systems</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Streaming Media Players</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">VCRs</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Video Glasses</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Lens Attachments</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column d-none d-lg-block py-4 text-center"><a class="d-block mb-2" href="#"><img src="https://cartzilla.createx.studio/img/shop/cart/02.jpg" alt="TV, Video &amp; Audio"></a>
                        <div class="font-size-sm mb-3">Starting from <span class="font-weight-medium">$78.<small>00</small></span></div><a class="btn btn-primary btn-shadow btn-sm" href="#">See offers<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="dropdown mega-dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown"><i class="czi-camera opacity-60 font-size-lg mt-n1 mr-2"></i>Cameras, Photo &amp; Video</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-flex flex-wrap flex-md-nowrap px-2">
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Cameras &amp; Lenses</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Point &amp; Shoot Digital Cameras</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">DSLR Cameras</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Mirrorless Cameras</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Body Mounted Cameras</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Camcorders</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Camcorder Lenses</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Camera Lenses</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Macro &amp; Ringlight Flashes</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Shoe Mount Flashes</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Tripods &amp; Monopods</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Video Studio</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Accessories</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Bags &amp; Cases</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Binoculars &amp; Scopes</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Batteries &amp; Chargers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Cables &amp; Cords</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Camcorder Accessories</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Cleaning Equipment</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Protector Foils</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Filters &amp; Accessories</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Remote Controls</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Rain Covers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Viewfinders</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column d-none d-lg-block py-4 text-center"><a class="d-block mb-2" href="#"><img src="https://cartzilla.createx.studio/img/shop/cart/03.jpg" alt="Cameras, Photo &amp; Video"></a>
                        <div class="font-size-sm mb-3">Starting from <span class="font-weight-medium">$210.<small>00</small></span></div><a class="btn btn-primary btn-shadow btn-sm" href="#">See offers<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="dropdown mega-dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown"><i class="czi-earphones opacity-60 font-size-lg mt-n1 mr-2"></i>Headphones</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-flex flex-wrap flex-md-nowrap px-2">
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Headphones</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Earbud Headphones</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Over-Ear Headphones</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">On-Ear Headphones</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Bluetooth Headphones</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Sports &amp; Fitness Headphones</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Noise-Cancelling Headphones</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Accessories</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Cases &amp; Sleeves</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Cables &amp; Cords</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Ear Pads</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Repair Kits</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Cleaning Equipment</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column d-none d-lg-block py-4 text-center"><a class="d-block mb-2" href="#">
                        <img src="https://cartzilla.createx.studio/img/shop/cart/01.jpg" alt="Headphones"></a>
                        <div class="font-size-sm mb-3">Starting from <span class="font-weight-medium">$35.<small>99</small></span></div><a class="btn btn-primary btn-shadow btn-sm" href="#">See offers<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="dropdown mega-dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown"><i class="czi-watch opacity-60 font-size-lg mt-n1 mr-2"></i>Wearable Electronics</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-flex flex-wrap flex-md-nowrap px-2">
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Gadgets</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Smartwatches</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Fitness Trackers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Smart Glasses</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Rings</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Virtual Reality</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Clips, Arm &amp; Wristbands</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Accessories</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column d-none d-lg-block py-4 text-center"><a class="d-block mb-2" href="#"><img src="https://cartzilla.createx.studio/img/shop/cart/02.jpg" alt="Wearable Electronics"></a>
                        <div class="font-size-sm mb-3">Starting from <span class="font-weight-medium">$79.<small>50</small></span></div><a class="btn btn-primary btn-shadow btn-sm" href="#">See offers<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="dropdown mega-dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown"><i class="czi-printer opacity-60 font-size-lg mt-n1 mr-2"></i>Printers &amp; Ink</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-flex flex-wrap flex-md-nowrap px-2">
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links mb-3">
                          <h6 class="font-size-base mb-3">By type</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">All-in-One</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Copying</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Faxing</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Photo Printing</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Printing Only</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Scanning</a></li>
                          </ul>
                        </div>
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Scanners</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Business Card Scanners</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Document Scanners</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Flatbed &amp; Photo Scanners</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Slide &amp; Negative Scanners</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base">Printers</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Dot Matrix Printers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Inkjet Printers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Label Printers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Laser Printers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Photo Printers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Wide Format Printers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Plotter Printers</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column d-none d-lg-block py-4 text-center"><a class="d-block mb-2" href="#"><img src="https://cartzilla.createx.studio/img/shop/cart/04.jpg" alt="Printers &amp; Ink"></a>
                        <div class="font-size-sm mb-3">Starting from <span class="font-weight-medium">$54.<small>00</small></span></div><a class="btn btn-primary btn-shadow btn-sm" href="#">See offers<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="dropdown mega-dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown"><i class="czi-joystick opacity-60 font-size-lg mt-n1 mr-2"></i>Video Games</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-flex flex-wrap flex-md-nowrap px-2">
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Games &amp; Hardware</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Video Games</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">PlayStation 4</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">PlayStation 3</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Xbox One</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Xbox 360</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Nintendo Switch</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Wii U</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Wii</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">PC</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Mac</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Nintendo 3DS &amp; 2DS</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Nintendo DS</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">PlayStation Vita</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Sony PSP</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Retro Gaming</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Microconsoles</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Accessories</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Digital Games</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column d-none d-lg-block py-4 text-center"><a class="d-block mb-2" href="#"><img src="https://cartzilla.createx.studio/img/shop/cart/01.jpg" alt="Video Games"></a>
                        <div class="font-size-sm mb-3">Starting from <span class="font-weight-medium">$19.<small>00</small></span></div><a class="btn btn-primary btn-shadow btn-sm" href="#">See offers<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="dropdown mega-dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown"><i class="czi-speaker opacity-60 font-size-lg mt-n1 mr-2"></i>Speakers &amp; Home Music</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-flex flex-wrap flex-md-nowrap px-2">
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Speakers</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Bluetooth Speakers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Bookshelf Speakers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Ceiling &amp; In-Wall Speakers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Center-Channel Speakers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Floorstanding Speakers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Outdoor Speakers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Satellite Speakers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Sound Bars</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Subwoofers</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Surround Sound Systems</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Home Audio</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Home Theater</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Wireless &amp; Streaming Audio</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Stereo System Components</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Compact Radios &amp; Stereos</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Home Audio Accessories</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column d-none d-lg-block py-4 text-center"><a class="d-block mb-2" href="#"><img src="https://cartzilla.createx.studio/img/shop/departments/16.jpg" alt="Speakers &amp; Home Music"></a>
                        <div class="font-size-sm mb-3">Starting from <span class="font-weight-medium">$43.<small>00</small></span></div><a class="btn btn-primary btn-shadow btn-sm" href="#">See offers<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="dropdown mega-dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown"><i class="czi-server opacity-60 font-size-lg mt-n1 mr-2"></i>HDD/SSD Data Storage</a>
                  <div class="dropdown-menu p-0">
                    <div class="d-flex flex-wrap flex-md-nowrap px-2">
                      <div class="mega-dropdown-column py-4 px-3">
                        <div class="widget widget-links">
                          <h6 class="font-size-base mb-3">Data Storage</h6>
                          <ul class="widget-list">
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">External Hard Drives</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">External Solid State Drives</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">External Zip Drives</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Floppy &amp; Tape Drives</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Internal Hard Drives</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Internal Solid State Drives</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">Network Attached Storage</a></li>
                            <li class="widget-list-item pb-1"><a class="widget-list-link" href="#">USB Flash Drives</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="mega-dropdown-column d-none d-lg-block py-4 text-center"><a class="d-block mb-2" href="#"><img src="https://cartzilla.createx.studio/img/shop/departments/15.jpg" alt="HDD/SSD Data Storage"></a>
                        <div class="font-size-sm mb-3">Starting from <span class="font-weight-medium">$21.<small>60</small></span></div><a class="btn btn-primary btn-shadow btn-sm" href="#">See offers<i class="czi-arrow-right font-size-xs ml-1"></i></a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <!-- Primary menu-->
          <ul class="navbar-nav">
            <li class="nav-item dropdown active">
              <a class="nav-link dropdown-toggle" routerLink="/">Home</a>
            </li>
          
            <li class="nav-item">
              <a class="nav-link" routerLink="/categoria">Productos</a>
            </li>
            <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Decoraciones Selines</a>
              <ul class="dropdown-menu">
                <li class="dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown">Navbar Variants</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="navbar-1-level-light.html">1 Level Light</a></li>
                    <li><a class="dropdown-item" href="navbar-1-level-dark.html">1 Level Dark</a></li>
                    <li><a class="dropdown-item" href="navbar-2-level-light.html">2 Level Light</a></li>
                    <li><a class="dropdown-item" href="navbar-2-level-dark.html">2 Level Dark</a></li>
                    <li><a class="dropdown-item" href="navbar-3-level-light.html">3 Level Light</a></li>
                    <li><a class="dropdown-item" href="navbar-3-level-dark.html">3 Level Dark</a></li>
                    <li><a class="dropdown-item" href="home-electronics-store.html">Electronics Store</a></li>
                    <li><a class="dropdown-item" href="home-marketplace.html">Marketplace</a></li>
                    <li><a class="dropdown-item" href="home-grocery-store.html">Side Menu (Grocery)</a></li>
                    <li><a class="dropdown-item" href="home-single-store.html">Transparent</a></li>
                  </ul>
                </li>
                <li class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="about.html">About Us</a></li>
                <li><a class="dropdown-item" href="contacts.html">Contacts</a></li>
                <li class="dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown">Help Center</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="help-topics.html">Help Topics</a></li>
                    <li><a class="dropdown-item" href="help-single-topic.html">Single Topic</a></li>
                    <li><a class="dropdown-item" href="help-submit-request.html">Submit a Request</a></li>
                  </ul>
                </li>
                <li class="dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown">404 Not Found</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="404-simple.html">404 - Simple Text</a></li>
                    <li><a class="dropdown-item" href="404-illustration.html">404 - Illustration</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown" hidden><a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Blog</a>
              <ul class="dropdown-menu">
                <li class="dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown">Blog List Layouts</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="blog-list-sidebar.html">List with Sidebar</a></li>
                    <li><a class="dropdown-item" href="blog-list.html">List no Sidebar</a></li>
                  </ul>
                </li>
                <li class="dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown">Blog Grid Layouts</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="blog-grid-sidebar.html">Grid with Sidebar</a></li>
                    <li><a class="dropdown-item" href="blog-grid.html">Grid no Sidebar</a></li>
                  </ul>
                </li>
                <li class="dropdown"><a class="dropdown-item dropdown-toggle" href="#" data-toggle="dropdown">Single Post Layouts</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="blog-single-sidebar.html">Article with Sidebar</a></li>
                    <li><a class="dropdown-item" href="blog-single.html">Article no Sidebar</a></li>
                  </ul>
                </li>
              </ul>
            </li>
       

            <li class="nav-item">
              <a class="nav-link" routerLink="/acerca">Quienes Somos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/promociones">Promociones</a>
            </li>
            <li class="nav-item ">
              <a class="nav-link" routerLink="/catalogo">Catálogos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/contacto">Contacto</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
