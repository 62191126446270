<app-navbar></app-navbar>
<main id="main" role="main">
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container my-5">
        <div class="row">
            <div class="col-lg-6 mb-9 mb-lg-0">
                <div class="mb-5">
                    <h1>Ponerse en contacto</h1>
                    <p>Nos encantaría hablar sobre cómo podemos ayudarlo.</p>
                </div>
                <iframe class="min-h-300rem mb-5" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5370.489342760302!2d-73.12473713487762!3d-36.83500152601733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9669ca24c65f412b%3A0x27a07a5c5d308e47!2sAlondras%20143%2C%20San%20Pedro%20de%20la%20Paz%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1605379296474!5m2!1ses!2scl" width="100%"  frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                <div class="row">
                    <div class="col-sm-6">
                      <div class="mb-3">
                        <span class="d-block h5 mb-1">Llámanos:</span>
                        <span class="d-block text-body font-size-1">+56 97895-6121</span>
                      </div>
        
                      <div class="mb-3">
                        <span class="d-block h5 mb-1">Email:</span>
                        <span class="d-block text-body font-size-1">contacto@selinestore.cl</span>
                      </div>
                    </div>
        
                    <div class="col-sm-6">
                      <div class="mb-3">
                        <span class="d-block h5 mb-1">Dirección:</span>
                        <span class="d-block text-body font-size-1">Alondras 143, San Pedro de la Paz, Concepción</span>
                      </div>
                    </div>
                  </div>
            </div>
            <div class="col-lg-6">
                <div class="ml-lg-5">
                    <div class="card shadow-lg border-0">
                        <div class="card-header border-0 bg-lights text-left py-4 px-4 px-md-6">
                            <h2 class="h4 mb-0">Consultas generales</h2>
                        </div>
                        <div class="card-body p-4 p-md-6 w-100">
                            <form [formGroup]="formContacto" (submit)="onSendMail()">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <mat-form-field  appearance="outline" class="w-100" color="accent">
                                            <mat-label>Nombre Completo</mat-label>
                                            <input matInput type="text"  placeholder="Nombre" formControlName="nombre">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6">
                                        <mat-form-field  appearance="outline" class="w-100" color="accent">
                                            <mat-label>Email</mat-label>
                                            <input matInput type="email"  placeholder="email" formControlName="email">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6">
                                        <mat-form-field  appearance="outline" class="w-100" color="accent">
                                            <mat-label>Telefono</mat-label>
                                            <input matInput type="text"  placeholder="Telefono" formControlName="telefono">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6">
                                        <mat-form-field  appearance="outline" class="w-100" color="accent">
                                            <mat-label>Asunto</mat-label>
                                            <input matInput type="text"  placeholder="Asunto" formControlName="asunto">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-12">
                                        <mat-form-field appearance="outline" class="w-100" color="accent">
                                            <mat-label>Mensaje</mat-label>
                                            <textarea rows="8" cols="80" matInput formControlName="mensaje"></textarea>
                                           
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div>
                                    <button type="submit" mat-raised-button color="accent" [disabled]="!formContacto.valid">Enviar Mensaje</button>
                                </div>
                            </form>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>
