import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';
import {FormGroup,FormBuilder,Validators} from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { debounceTime } from 'rxjs/operators';
////////////////// externos //////////
import Swal from 'sweetalert2'

///////////////// material ui ////////
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-productos-detalles',
  templateUrl: './productos-detalles.component.html',
  styleUrls: ['./productos-detalles.component.css']
})
export class ProductosDetallesComponent implements OnInit {
  productos$:Observable<any> | undefined;
  ratings$:Observable<any> | undefined;
  images: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  productosrelacionados: any;
  progressbar:any;
  responsiveOptionsCarousel:any;
  promediototal:any;
  colorlabel:string='';
  token:string | null='';
  token1:any;
  responsiveOptions:any[] = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];
    
  items:any;
  breadcrumbitems={
    type:'product',
    title:'',
    items:[{
      title:"Todos los Productos",
      routerlink:'/categoria'
    }]
  }
  formRatings:FormGroup;
  formItems:FormGroup;
  private id:string | null='';
  constructor(
    private apiService:ApiService,
    private rutaActiva:ActivatedRoute,
    private formBuilder:FormBuilder,
    private _snackBar: MatSnackBar
    
  ) {
    this.formRatings = this.formBuilder.group({
      id:[''],
      nombre:['',Validators.required],
      email:['',[Validators.required,Validators.email]],
      calificacion:['',Validators.required],
      comentarios:['',Validators.required],
      pros:[''],
      contras:[''],
    });
    this.formItems = this.formBuilder.group({
      id:['',Validators.required],
      titulo:['',Validators.required],
      color:['',Validators.required],
      precio:['',Validators.required],
      precioformateado:['',Validators.required],
      medidas:['',Validators.required],
      cantidad:[1,Validators.required]
    });
    this.formItems.valueChanges.pipe(debounceTime(500)).subscribe(value=>{
      this.colorlabel=value.color;
    })
    this.responsiveOptionsCarousel = [
      {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '768px',
          numVisible: 2,
          numScroll: 2
      },
      {
          breakpoint: '560px',
          numVisible: 1,
          numScroll: 1
      }
    ];
   }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.rutaActiva.paramMap.subscribe(params => {
      this.id=params.get("id");
      this.getProductoDetalles(this.id);
      this.getRatings(this.id);
    });
  }
  openSnackBar() {
    this._snackBar.open('Artículo agregado al carro de compra', '', {
      duration: 500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  getProductosRelation(categoria:string){
    this.apiService.getProductosRelacion(categoria).subscribe(result=>{
      this.productosrelacionados = result;
    });
  }
  getProductoDetalles(id:string | null){
    this.productos$=this.apiService.getProductosDetalles(id);
    this.productos$.subscribe(result => {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      this.colorlabel=result.colores[0].colornombre;
      this.formItems.patchValue({
        id:result._id,
        titulo:result.titulo,
        color:this.colorlabel,
        precio:result.precio,
        cantidad:1,
        precioformateado:result.precioformateado
      })
      
      const img:any=[];
      result.imagenproductos.forEach((element: any)=>{
        img.push({
          "previewImageSrc": element,
          "thumbnailImageSrc": element,
          "alt": "Productos",
          "title": result.titulo
        });
      })
      this.getProductosRelation(result.categoria);
      this.images=img;
      this.breadcrumbitems.title=result.titulo;
      this.breadcrumbitems.items=[{
        title:"Todos los Productos",
        routerlink:'/categoria'
      },
      {
        title:result.titulo,
        routerlink:''
      }];
      this.items=Array.from({length: result.cantidad}).map((_, i) => i+1);
    })
  }

  onPostRatings(){
    const value=this.formRatings.value;
    value.id=this.id;
    this.apiService.postRatings(value).subscribe(result=>{
      if(result.res){
        Swal.fire({
          icon: 'success',
          title:'Calificación enviada',
          showConfirmButton: false,
          timer: 3000
        })
        this.getRatings(this.id);
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Calificación no fue enviada',
          showConfirmButton: false,
          timer: 3000
        })
      }
    })
  }

  getRatings(id:string | null){
   this.ratings$ = this.apiService.getRatings(id);
   this.ratings$.subscribe(result=>{
      this.progressbar=result.ratingpromedios;
      this.promediototal=result.ratingpromediototal;
   })
  }
  addWishlist(id:string){
    console.log(id);
  }
  addToCart(){
  
    let cart = this.formItems.value;
    cart.preciounitario = cart.precio;
    cart.preciounitarioformateado = cart.precioformateado; 
    cart.img = this.images[0].previewImageSrc;
    cart.url = `/productos/${this.id}`;
    
    if(localStorage.getItem("token")==null){
      this.apiService.postCart(cart).subscribe(result=>{
        if(result.res){
          localStorage.setItem("token",result.data._id);
          localStorage.setItem("token1",result.token);
          this.token=localStorage.getItem("token");
          localStorage.setItem("token1",result.token);
          this.token1=localStorage.getItem("token1");
          this.openSnackBar();
        }
      })
    }else{
      const data={
        id:localStorage.getItem("token"),
        data:cart,
        token:localStorage.getItem("token1"),
      }
      this.apiService.editCart(data).subscribe(result=>{
        if(result){
          this.token=localStorage.getItem("token");
          localStorage.setItem("token1",result.token);
          this.token1=localStorage.getItem("token1");
          console.log(this.token1);
          this.openSnackBar();
        }
      });

    }

  }
  likeRatings(id:string){
    const body={
      id:id
    }
    this.apiService.postLikeRatings(body).subscribe(result=>{
      if(result.res){
        this.getRatings(this.id);
      }
    })
  }

  dislikeRatings(id:string){
  
    const body={
      id:id
    }
    this.apiService.postDisLikeRatings(body).subscribe(result=>{
      if(result.res){
        this.getRatings(this.id);
      }
    })
  }

}


