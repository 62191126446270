import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tracking-details',
  templateUrl: './tracking-details.component.html',
  styleUrls: ['./tracking-details.component.css']
})
export class TrackingDetailsComponent implements OnInit {
  breadcrumbitems={
    type:'normal',
    title:'Rastreo de orden',
    tracking:'34VB5540K83',
    items:[{
      title:"Rastreo de orden",
      routerlink:'/tracking'
    },
    {
      title:"34VB5540K83",
      routerlink:''
    }
    ]
  }
  constructor() { }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

}
