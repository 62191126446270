import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-tickets',
  templateUrl: './account-tickets.component.html',
  styleUrls: ['./account-tickets.component.css']
})
export class AccountTicketsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
