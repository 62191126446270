import { Component, OnInit } from '@angular/core';
////// service ////
import {ApiService} from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
///////////////// material ui ////////
import {MessageService} from 'primeng/api';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  productos:any;
  numberItemsWishlist:number = 0;
  token:string | null='';
  token1:string | null='';
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private apiService:ApiService,
    private authService:AuthService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    
    this.getProductos();
  }
  getProductos(){
    this.apiService.getProductosHome().subscribe(result=>{
      this.productos=result;
    })
  }
  openSnackBar(message:string) {
    this._snackBar.open(message, '', {
      duration: 500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  addCart(element:any){
    
    const cart={
      id:element._id,
      titulo:element.titulo,
      color:element.colores[0].colornombre,
      precio:element.precio,
      precioformateado:element.precioformateado,
      preciounitario:element.precio,
      preciounitarioformateado:element.precioformateado,
      img:element.imagenproductos[0],
      medidas:element.tallas[0],
      cantidad:1,
      url:`/productos/${element._id}`
    }
    if(localStorage.getItem("token")==null){
      this.apiService.postCart(cart).subscribe(result=>{
        if(result.res){
          localStorage.setItem("token",result.data._id);
          localStorage.setItem("token1",result.token);
          this.token=localStorage.getItem("token");
          localStorage.setItem("token1",result.token);
          this.token1=localStorage.getItem("token1");
          this.openSnackBar('Artículo agregado al carro de compra');
        }
      })
    }else{
      const data={
        id:localStorage.getItem("token"),
        data:cart,
        token:localStorage.getItem("token1"),
      }
      this.apiService.editCart(data).subscribe(result=>{
        if(result){
          this.token=localStorage.getItem("token");
          localStorage.setItem("token1",result.token);
          this.token1=localStorage.getItem("token1");
          console.log(this.token1);
          this.openSnackBar('Artículo agregado al carro de compra');
        }
      });

    }
  
  }

  getWishlist(user:string){
    this.apiService.getWishlist(user).subscribe(result=>{
      this.numberItemsWishlist=result.items.length;
    })
  }

  addWishlist(data:any){
    const user=this.authService.getToken();
    const formData={
      token:user,
      items:data,
    }
    if(this.authService.loggedIn()){
      this.apiService.postAddwishlist(formData).subscribe(result=>{
        if(result.res){
          this.getWishlist(user);
          this.openSnackBar(result.message);
        }else{
          this.openSnackBar(result.message);
        }
      });
    }
  }


  

}
