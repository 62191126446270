<div class="cz-sidebar rounded-lg box-shadow-lg">
    <div class="cz-sidebar-body">

        <div class="widget widget-categories mb-4 pb-4 border-bottom">
            <h3 class="widget-title">Categoria</h3>
            <div id="shop-categories" class="accordion mt-n1">
                <mat-accordion>

                    <mat-expansion-panel class="shadow-none" (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Arreglos
                        </mat-panel-title>
                        
                      </mat-expansion-panel-header>
                      <div class="card-body">
                          <div class="widget widget-links cz-filter">
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label>Buscar</mat-label>
                                <input matInput placeholder="Buscar..">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>

                            <cdk-virtual-scroll-viewport itemSize="10" class="widget-list cz-filter-list pt-1 h-12 scroll-ui">
                                <li *cdkVirtualFor="let item of items" class="widget-list-item cz-filter-item">
                                    <a href="#" class="widget-list-link d-flex justify-content-between align-items-center">
                                        <span class="cz-filter-item-text">{{item}}</span>
                                        <span class="font-size-xs text-muted ml-3">1,953</span>
                                    </a>
                                </li>
                            </cdk-virtual-scroll-viewport>
    
                          </div>
                      </div>
                    </mat-expansion-panel>
                    
                    <mat-expansion-panel class="shadow-none" (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Globos
                        </mat-panel-title>
                        
                      </mat-expansion-panel-header>
                      <div class="card-body">
                          <div class="widget widget-links cz-filter">
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label>Buscar</mat-label>
                                <input matInput placeholder="Buscar..">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>

                            <cdk-virtual-scroll-viewport itemSize="10" class="widget-list cz-filter-list pt-1 h-12 scroll-ui">
                                <li *cdkVirtualFor="let item of items" class="widget-list-item cz-filter-item">
                                    <a href="#" class="widget-list-link d-flex justify-content-between align-items-center">
                                        <span class="cz-filter-item-text">{{item}}</span>
                                        <span class="font-size-xs text-muted ml-3">1,953</span>
                                    </a>
                                </li>
                            </cdk-virtual-scroll-viewport>
    
                          </div>
                      </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="shadow-none" (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Accesorios de Globo
                        </mat-panel-title>
                        
                      </mat-expansion-panel-header>
                      <div class="card-body">
                          <div class="widget widget-links cz-filter">
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label>Buscar</mat-label>
                                <input matInput placeholder="Buscar..">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>

                            <cdk-virtual-scroll-viewport itemSize="10" class="widget-list cz-filter-list pt-1 h-12 scroll-ui">
                                <li *cdkVirtualFor="let item of items" class="widget-list-item cz-filter-item">
                                    <a href="#" class="widget-list-link d-flex justify-content-between align-items-center">
                                        <span class="cz-filter-item-text">{{item}}</span>
                                        <span class="font-size-xs text-muted ml-3">1,953</span>
                                    </a>
                                </li>
                            </cdk-virtual-scroll-viewport>
    
                          </div>
                      </div>
                    </mat-expansion-panel>


                    <mat-expansion-panel class="shadow-none" (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Cumpleaños
                        </mat-panel-title>
                        
                      </mat-expansion-panel-header>
                      <div class="card-body">
                          <div class="widget widget-links cz-filter">
                            <mat-form-field appearance="outline" color="accent">
                                <mat-label>Buscar</mat-label>
                                <input matInput placeholder="Buscar..">
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>

                            <cdk-virtual-scroll-viewport itemSize="10" class="widget-list cz-filter-list pt-1 h-12 scroll-ui">
                                <li *cdkVirtualFor="let item of items" class="widget-list-item cz-filter-item">
                                    <a href="#" class="widget-list-link d-flex justify-content-between align-items-center">
                                        <span class="cz-filter-item-text">{{item}}</span>
                                        <span class="font-size-xs text-muted ml-3">1,953</span>
                                    </a>
                                </li>
                            </cdk-virtual-scroll-viewport>
    
                          </div>
                      </div>
                    </mat-expansion-panel>

                </mat-accordion>
            </div>
        </div>

        <div class="widget mb-4 pb-4 border-bottom">
            <h3 class="widget-title">Precio</h3>
            <div class="py-3">
                 <h5>Range: {{rangeValues[0] + ' - ' + rangeValues[1]}}</h5>
                 <p-slider [(ngModel)]="rangeValues" [range]="true"></p-slider>
            </div>
           
            <div class="d-flex pb-1">
                <div class="w-50 pr-2 mr-2">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                    <input class="form-control cz-range-slider-value-min" type="text">
                  </div>
                </div>
                <div class="w-50 pl-2">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                    <input class="form-control cz-range-slider-value-max" type="text">
                  </div>
                </div>
            </div>
        </div>
        <div class="widget cz-filter mb-4 pb-4 border-bottom">
            <h3 class="widget-title">Marcas</h3>
            <div class="card-body">
                <div class="widget widget-links cz-filter">
                  <mat-form-field appearance="outline" color="accent">
                      <mat-label>Buscar</mat-label>
                      <input matInput placeholder="Buscar..">
                      <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>

                  <cdk-virtual-scroll-viewport itemSize="10" class="widget-list cz-filter-list pt-1 h-12 scroll-ui">
                      <li *cdkVirtualFor="let category of categories" class="cz-filter-item d-flex justify-content-between align-items-center mb-2">
                        <div class="p-field-checkbox mb-0">
                            <p-checkbox name="group2" value="category" [value]="category"  [(ngModel)]="selectedCategories" [inputId]="category.key" [disabled]="category.key === 'R'"></p-checkbox>
                            <label [for]="category.key">{{category.name}}</label>
                        </div>
                        <span class="font-size-xs text-muted">425</span>
                      </li>
                  </cdk-virtual-scroll-viewport>

                </div>
            </div>
        </div>
        <div class="widget cz-filter mb-4 pb-4 border-bottom">
            <h3 class="widget-title">Tamaños</h3>
            <div class="card-body">
                <div class="widget widget-links cz-filter">
                  <mat-form-field appearance="outline" color="accent">
                      <mat-label>Buscar</mat-label>
                      <input matInput placeholder="Buscar..">
                      <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>

                  <cdk-virtual-scroll-viewport itemSize="10" class="widget-list cz-filter-list pt-1 h-12 scroll-ui">
                      <li *cdkVirtualFor="let size of sizes" class="cz-filter-item d-flex justify-content-between align-items-center mb-2">
                        <div class="p-field-checkbox mb-0">
                            <p-checkbox name="group2" value="size" [value]="size"  [(ngModel)]="selectedCategories" [inputId]="size.key"></p-checkbox>
                            <label [for]="size.key">{{size.name}}</label>
                        </div>
                        <span class="font-size-xs text-muted">425</span>
                      </li>
                  </cdk-virtual-scroll-viewport>

                </div>
            </div>
        </div>

        <div class="widget">
          <h3 class="widget-title">Color</h3>
          <div class="d-flex flex-wrap">
            <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
              <input class="custom-control-input" type="checkbox" id="color-blue-gray">
              <label class="custom-option-label rounded-circle" for="color-blue-gray"><span class="custom-option-color rounded-circle" style="background-color: #b3c8db;"></span></label>
              <label class="d-block font-size-xs text-muted mt-n1" for="color-blue-gray">Blue-gray</label>
            </div>
            <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
              <input class="custom-control-input" type="checkbox" id="color-burgundy">
              <label class="custom-option-label rounded-circle" for="color-burgundy"><span class="custom-option-color rounded-circle" style="background-color: #ca7295;"></span></label>
              <label class="d-block font-size-xs text-muted mt-n1" for="color-burgundy">Burgundy</label>
            </div>
            <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
              <input class="custom-control-input" type="checkbox" id="color-teal">
              <label class="custom-option-label rounded-circle" for="color-teal"><span class="custom-option-color rounded-circle" style="background-color: #91c2c3;"></span></label>
              <label class="d-block font-size-xs text-muted mt-n1" for="color-teal">Teal</label>
            </div>
            <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
              <input class="custom-control-input" type="checkbox" id="color-brown">
              <label class="custom-option-label rounded-circle" for="color-brown"><span class="custom-option-color rounded-circle" style="background-color: #9a8480;"></span></label>
              <label class="d-block font-size-xs text-muted mt-n1" for="color-brown">Brown</label>
            </div>
            <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
              <input class="custom-control-input" type="checkbox" id="color-coral-red">
              <label class="custom-option-label rounded-circle" for="color-coral-red"><span class="custom-option-color rounded-circle" style="background-color: #ff7072;"></span></label>
              <label class="d-block font-size-xs text-muted mt-n1" for="color-coral-red">Coral red</label>
            </div>
            <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
              <input class="custom-control-input" type="checkbox" id="color-navy">
              <label class="custom-option-label rounded-circle" for="color-navy"><span class="custom-option-color rounded-circle" style="background-color: #696dc8;"></span></label>
              <label class="d-block font-size-xs text-muted mt-n1" for="color-navy">Navy</label>
            </div>
            <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
              <input class="custom-control-input" type="checkbox" id="color-charcoal">
              <label class="custom-option-label rounded-circle" for="color-charcoal"><span class="custom-option-color rounded-circle" style="background-color: #4e4d4d;"></span></label>
              <label class="d-block font-size-xs text-muted mt-n1" for="color-charcoal">Charcoal</label>
            </div>
            <div class="custom-control custom-option text-center mb-2 mx-1" style="width: 4rem;">
              <input class="custom-control-input" type="checkbox" id="color-sky-blue">
              <label class="custom-option-label rounded-circle" for="color-sky-blue"><span class="custom-option-color rounded-circle" style="background-color: #8bcdf5;"></span></label>
              <label class="d-block font-size-xs text-muted mt-n1" for="color-sky-blue">Sky blue</label>
            </div>
          </div>
        </div>

    </div>
</div>
