import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { debounce, debounceTime } from 'rxjs/operators';

import { AuthService } from '../../../services/auth.service';
import { ApiService } from '../../../services/api.service';
import { UserService } from '../../../services/user.service';
////////// utils //////
import { Validation } from '../../../utils/Validators';
////////// externos /////////////
import Swal from 'sweetalert2';

@Component({
  selector: 'app-account-segurity',
  templateUrl: './account-segurity.component.html',
  styleUrls: ['./account-segurity.component.css']
})

export class AccountSegurityComponent implements OnInit {
  type:string='left';
  formSegurity:FormGroup;
  userInfo:any;
  breadcrumbitems={
    type:'product',
    title:'Información de perfil',
    items:[{
      title:"Cuenta",
      routerlink:'/account-segurity'
    },
    {
      title:'Seguridad y Contraseña',
      routerLink:''
    }
    ]
  }
  constructor(
    private formBuilder:FormBuilder,
    private apiService:ApiService,
    public authService:AuthService,
    private userService:UserService
  ) {
    this.formSegurity = this.formBuilder.group(
      {
        password: ["", Validators.required],
        confirmarPassword: ["", Validators.required],
      },
      {
        validators: Validation.validarQueSeanIguales,
      }
    );
    
   }

  ngOnInit(): void {
  }
  onChangePassword(){
    let value=this.formSegurity.value;
    const token=this.authService.getToken();
    value.id = (this.authService.decodeToken(token))._id;
    this.userService.changePassword(value).subscribe(result=>{
      
      if(result.res){
        Swal.fire(
          'Mensaje Enviado!',
          'Contraseña cambiada',
          'success'
        )
        this.formSegurity.reset();
      }else{
        Swal.fire(
          'Error!',
          'error al cambiar contraseña',
          'error'
        )
      }
    })
  }

  /*checarSiSonIguales(): boolean {
    return  this.formSegurity.hasError('noSonIguales')  &&
      this.formSegurity.get('password').dirty &&
      this.formSegurity.get('confirmarPassword').dirty;
  }*/
 
}
