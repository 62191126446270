<app-navbar [token]="token1" [numberItemsWishlist]="numberItemsWishlist"></app-navbar>
<main>
<section class="pb-5">
  <app-slider></app-slider>
</section>
<ng-container *ngIf="productos">

  <section class="container pb-5 px-0">
    <div class="d-flex flex-wrap justify-content-between align-items-center pt-1 border-bottom pb-4 mb-4">
      <h2 class="h3 mb-0 pt-3 mr-2">Productos Destacados</h2>
      <div class="pt-3">
        <a class="btn btn-outline-accent btn-sm" routerLink="/categoria">Mas productos<i class="fas fa-chevron-right ml-1 mr-n1"></i></a></div>
    </div>
    <div class="row pt-4 mx-n2" *ngIf="productos.destacados;else showLoadding">

      <div class="col-lg-3 col-md-4 col-sm-6 px-2 mb-4" *ngFor="let products of productos.destacados; index as i">
        <div class="card product-card">
          <span *ngIf="products.descuentos!=0" class="badge badge-danger badge-shadow">Sale</span>
          <button class="btn-wishlist btn-sm" type="button" matTooltip="Agregar mi lista de deseos" (click)="addWishlist(products)">
            <i class="czi-heart far fa-heart"></i>
          </button>
          <a class="card-img-top d-block overflow-hidden" routerLink="/productos/{{products._id}}">
            <img class="mh-200" src="{{products.imagenproductos[0]}}" alt="Producto">
          </a>
          <div class="card-body py-2">
            <a class="product-meta d-block font-size-xs pb-1" routerLink="#">{{products.categoria}}</a>
            <h3 class="product-title font-size-sm">
              <a routerLink="/productos/{{products._id}}">{{products.titulo}}</a>
            </h3>
            <div class="d-flex justify-content-between">
              <div class="product-price">
                <span class="text-accent">${{products.precioformateado}}</span>
                <del class="font-size-sm text-muted" hidden>$38.<small>50</small></del>
              </div>
              <div class="star-rating">
                <ng-rating-bar class="rating-card" [value]="products.ratings" [ratingCount]="5" [disabled]="true"  colorActive="#fea569" colorDefault="#8c98a4"></ng-rating-bar>
              </div>
            </div>
          </div>
          <div class="card-body card-body-hidden">
            <div class="text-center pb-2">
              <div class="custom-control custom-option custom-control-inline mb-2" *ngFor="let color of products.colores">
                <input class="custom-control-input" type="radio" name="{{products._id}}" id="{{i}}{{color.colorcodigo}}" >
                <label class="custom-option-label rounded-circle" for="{{i}}{{color.colorcodigo}}"><span class="custom-option-color rounded-circle" [ngStyle]="{'background-color': color.colorcodigo}"></span></label>
              </div>
              
            </div>
            <div class="d-flex mb-2">
              <select class="custom-select custom-select-sm mr-2 w-50">
                <option *ngFor="let tallas of products.tallas">{{tallas}}</option>
              </select>
              <button class="btn btn-purple btn-sm" type="button" matTooltip="Agregar al Carro de compra" (click)="addCart(products)"><i class="fas fa-cart-plus font-size-sm mr-1"></i>Agregar</button>
            </div>
            <div class="text-center" hidden>
              <a class="nav-link-style font-size-ms" href="#quick-view" data-toggle="modal">
                <i class="far fa-eye align-middle mr-1"></i>
                Vista rápida
              </a>
            </div>
          </div>
        </div>
        <hr class="d-sm-none">
      </div>







    </div>
    <ng-template #showLoadding>
      <div class="h-70vh">
        <div class="d-flex justify-content-center align-items-center">
          <app-spinner></app-spinner>
        </div>
      </div>
    </ng-template>
  </section>

  <section class="container pb-4 mb-md-3">
    <div class="row">
      <div class="col-md-8 mb-4">
        <div class="d-sm-flex justify-content-between align-items-center bg-secondary overflow-hidden rounded-lg" style="background-image: url(assets/image/banner/banner-bg.jpg); background-size: cover;">
          <div class="py-4 my-2 my-md-0 py-md-5 px-4 ml-md-3 text-center text-sm-left">
            <h4 class="font-size-lg font-weight-light mb-2">¡Darse prisa! Oferta por tiempo limitado</h4>
            <h3 class="mb-4">Globos Rosa Fold en venta</h3><a class="btn btn-primary btn-shadow btn-sm" href="#">Compra ahora</a>
          </div><img class="d-block h-100 mh-230 ml-auto" src="assets/image/banner/rosegold.png" alt="Comprar globos">
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="d-flex flex-column h-100 justify-content-center bg-size-cover bg-position-center rounded-lg" style="background-image: url(assets/image/banner/banner-bg.jpg);">
          <div class="py-4 my-2 px-4 text-center">
            <div class="py-1">
              <h5 class="mb-2">Your Add Banner Here</h5>
              <p class="font-size-sm text-muted">Hurry up to reserve your spot</p>
              <a class="btn btn-primary btn-shadow btn-sm" href="#">Contacto</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container pb-4 pb-md-5">
    <div class="row">
      <!-- Bestsellers-->
      <div class="col-lg-4 col-md-6 mb-2 py-3">
        <div class="widget">
          <h3 class="widget-title">Superventas</h3>
          <div class="media align-items-center pb-2 border-bottom" *ngFor="let product of productos.superventas">
            <a class="d-block mr-2" routerLink="/productos/{{product._id}}">
              <img src="{{product.imagenproductos[0]}}" alt="Product" width="64"></a>
            <div class="media-body">
              <h6 class="widget-product-title"><a  routerLink="/productos/{{product._id}}">{{product.titulo}}</a></h6>
              <div class="widget-product-meta"><span class="text-accent">${{product.precioformateado}}</span></div>
            </div>
          </div>
          
          <p class="mb-0">...</p><a class="font-size-sm" href="shop-grid-ls.html">Ver más<i class="czi-arrow-right font-size-xs ml-1"></i></a>
        </div>
      </div>
      <!-- New arrivals-->
      <div class="col-lg-4 col-md-6 mb-2 py-3">
        <div class="widget">
          <h3 class="widget-title">Nuevos Productos</h3>
          <div class="media align-items-center pb-2 border-bottom" *ngFor="let product of productos.ultimosproductos">
            <a class="d-block mr-2" routerLink="/productos/{{product._id}}">
              <img src="{{product.imagenproductos[0]}}" alt="Product" width="64"></a>
            <div class="media-body">
              <h6 class="widget-product-title"><a  routerLink="/productos/{{product._id}}">{{product.titulo}}</a></h6>
              <div class="widget-product-meta"><span class="text-accent">${{product.precioformateado}}</span></div>
            </div>
          </div>
          <p class="mb-0">...</p><a class="font-size-sm" href="shop-grid-ls.html">Ver más<i class="czi-arrow-right font-size-xs ml-1"></i></a>
        </div>
      </div>
      <!-- Top rated-->
      <div class="col-lg-4 col-md-6 mb-2 py-3">
        <div class="widget">
          <h3 class="widget-title">Los más valorados</h3>
          <div class="media align-items-center pb-2 border-bottom" *ngFor="let product of productos.masvalorado">
            <a class="d-block mr-2" routerLink="/productos/{{product._id}}">
              <img src="{{product.imagenproductos[0]}}" alt="Product" width="64"></a>
            <div class="media-body">
              <h6 class="widget-product-title"><a  routerLink="/productos/{{product._id}}">{{product.titulo}}</a></h6>
              <div class="widget-product-meta"><span class="text-accent">${{product.precioformateado}}</span></div>
            </div>
          </div>
          <p class="mb-0">...</p><a class="font-size-sm" href="shop-grid-ls.html">Ver más<i class="czi-arrow-right font-size-xs ml-1"></i></a>
        </div>
      </div>
    </div>
  </section>

</ng-container>
<section class="container-fluid px-0">
    <div class="row no-gutters">
    <div class="col-md-6">
          <a class="card border-0 rounded-0 text-decoration-none py-md-4 bg-faded-primary" href="blog-list-sidebar.html">
          <div class="card-body text-center">
            <i class="far fa-edit h3 mt-2 mb-4 text-orange"></i>
            <h3 class="h5 mb-1">Siguenos en nuestro blog</h3>
            <p class="text-muted font-size-sm">Latest store, fashion news and trends</p>
          </div>
        </a>
    </div>
      <div class="col-md-6">
          <a class="card border-0 rounded-0 text-decoration-none py-md-4 bg-faded-accent" href="https://www.instagram.com/selinestore_/">
            <div class="card-body text-center">
                <i class="fab fa-instagram h3 mt-2 mb-4 text-accent"></i>
                <h3 class="h5 mb-1">Siguenos en Instagram</h3>
                <p class="text-muted font-size-sm">@selinestore_</p>
            </div>
         </a>
      </div>
    </div>
  </section>
</main>
<app-footer></app-footer>