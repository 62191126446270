import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var gtag:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'selinestore';

  constructor(
    private spinnerService:NgxSpinnerService,
    private router: Router
  ){
    const navEndEvents$ = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents$.subscribe((event: NavigationEnd | any) => {
      gtag('config', 'G-LC5C7YKTPC', {
        'page_path': event.urlAfterRedirects
      });
    });
  
  }
  ngOnInit(){
    this.spinner()
  }
  
  spinner():void{
    this.spinnerService.show();
    setTimeout(()=>{
      this.spinnerService.hide();
    },3000)
  }
}

