import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) { }
  intercept(req:HttpRequest<any>,next:HttpHandler): Observable<any>{
    if(req.url.includes('admin')){
      let tokenizeReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken()}`
        }
      });
      return next.handle(tokenizeReq);
    }
    return next.handle(req);
  }
  
}
