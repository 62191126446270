<app-navbar [token]="token"></app-navbar>
<main>
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container pb-5 mb-2 mb-md-4 page-title-overlapis">
        <div class="row">
            <section class="col-lg-8">
                <div class="d-flex justify-content-between align-items-center pt-3 pb-2 pb-sm-5 mt-1">
                    <h2 class="h6 text-light mb-0">Productos</h2><a class="btn btn-outline-primary btn-sm pl-2" routerLink="/categoria"><i class="fas fa-chevron-left mr-2"></i>Seguir comprando</a>
                </div>
                <div *ngIf="data;else showEmtyCart" id="items-cart">
                    <div class="d-sm-flex justify-content-between align-items-center my-4 pb-3 border-bottom" *ngFor="let item of data.items">
                        <div class="media media-ie-fix d-block d-sm-flex align-items-center text-center text-sm-left">
                          <a class="d-inline-block mx-auto mr-sm-4" href="shop-single-v1.html" style="width: 10rem;">
                            <img src="{{item.img}}" alt="Producto"></a>
                          <div class="media-body pt-2">
                            <h3 class="product-title font-size-base mb-2">
                                <a href="shop-single-v1.html">{{item.titulo}}</a>
                            </h3>
                            <div class="font-size-sm">
                                <span class="text-muted mr-2">Medidas:</span>{{item.medida}}
                            </div>
                            <div class="font-size-sm">
                                <span class="text-muted mr-2">Color:</span>{{item.color}}
                            </div>
                            <div class="font-size-lg text-accent pt-2">${{item.precioformateado}},<small>00</small></div>
                          </div>
                        </div>
                        <div class="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-left" style="max-width: 9rem;">
                          <div class="form-group mb-0">
                            <label class="font-weight-medium" for="quantity1">Cantidad</label>
                            <input class="form-control" type="number" id="quantity1" (change)="changeQualityItem(data.id,$event)" value="{{item.cantidad}}">
                          </div>
                          <button class="btn btn-link px-0 text-danger text-decoration-none" type="button" (click)="deleteItems(item.id)">
                              <i class="czi-close-circle far fa-times-circle mr-2"></i><span class="font-size-sm">Eliminar</span>
                          </button>
                        </div>
                    </div>
                </div>
                <ng-template #showEmtyCart>
                  <div class="w-md-80 w-lg-50 text-center mx-md-auto">
                    <figure class="max-w-10rem max-w-sm-15rem mx-auto mb-3">
                      <img src="assets/image/svg/empty-cart.svg" alt="SVG" class="img-fluid">
                    </figure>
                  </div>
                </ng-template>
                <button class="btn btn-outline-danger btn-block" type="button" (click)="deleteAllItems()">
                    <i class="czi-loading far fa-trash-alt font-size-base mr-2"></i>Vaciar Carrito
                </button>
            </section>
            <aside class="col-lg-4 pt-4 pt-lg-0">
                <div class="cz-sidebar-static rounded-lg box-shadow-lg ml-lg-auto">
                    <div class="text-center mb-4 pb-3 border-bottom">
                      <h2 class="h6 mb-3 pb-1">Subtotal</h2>
                      <h3 class="font-weight-normal">${{amountCart}},<small>00</small></h3>
                    </div>
                    <div class="form-group mb-4">
                      <label class="mb-3" for="order-comments">
                          <span class="badge badge-info font-size-xs mr-2">Nota</span>
                          <span class="font-weight-medium">Comentarios adicionales</span>
                        </label>
                      <textarea class="form-control" rows="6" id="order-comments"></textarea>
                    </div>
                    <div class="accordion" id="order-options">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="accordion-heading">
                              <a href="#promo-code" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="promo-code" class="collapsed">Aplicar código promocional
                                  <span class="accordion-indicator">
                                    <i class="fas fa-chevron-up"></i>
                                  </span>
                              </a>
                          </h3>
                        </div>
                        <div class="collapse" id="promo-code" data-parent="#order-options" style="">
                          <form class="card-body needs-validation" method="post" novalidate="">
                            <div class="form-group">
                              <input class="form-control" type="text" placeholder="Código promocional">
                              <div class="invalid-feedback">Proporcione el código de promoción.</div>
                            </div>
                            <button class="btn btn-outline-primary btn-block" type="submit">Aplicar código promocional</button>
                          </form>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header">
                          <h3 class="accordion-heading">
                              <a class="collapsed" href="#shipping-estimates" role="button" data-toggle="collapse" aria-expanded="true" aria-controls="shipping-estimates">Estimaciones de envío
                                    <span class="accordion-indicator">
                                        <i class="fas fa-chevron-up"></i>
                                    </span>
                              </a>
                          </h3>
                        </div>
                        <div class="collapse" id="shipping-estimates" data-parent="#order-options">
                          <div class="card-body">
                            <form class="needs-validation" novalidate="">
                              <div class="form-group">
                                <select class="form-control custom-select" required="">
                                  <option value="">Elige tu Región</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Canada">Canada</option>
                                  <option value="Finland">Finland</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="New Zealand">New Zealand</option>
                                  <option value="Switzerland">Switzerland</option>
                                  <option value="United States">United States</option>
                                </select>
                                <div class="invalid-feedback">Please choose your country!</div>
                              </div>
                              <div class="form-group">
                                <select class="form-control custom-select" required="">
                                  <option value="">Elige tu Comuna</option>
                                  <option value="Bern">Bern</option>
                                  <option value="Brussels">Brussels</option>
                                  <option value="Canberra">Canberra</option>
                                  <option value="Helsinki">Helsinki</option>
                                  <option value="Mexico City">Mexico City</option>
                                  <option value="Ottawa">Ottawa</option>
                                  <option value="Washington D.C.">Washington D.C.</option>
                                  <option value="Wellington">Wellington</option>
                                </select>
                                <div class="invalid-feedback">Please choose your city!</div>
                              </div>
                              <div class="form-group">
                                <input class="form-control" type="text" placeholder="ZIP / Postal code" required="">
                                <div class="invalid-feedback">Please provide a valid zip!</div>
                              </div>
                              <button class="btn btn-outline-primary btn-block" type="submit">Calculate shipping</button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a class="btn btn-purple btn-shadow btn-block mt-4" routerLink="/checkout-details">
                        <i class="czi-card far fa-credit-card font-size-lg mr-2"></i>
                        Pasar por la caja
                    </a>
                  </div>
            </aside>
        </div>
    </div>
</main>
<app-footer></app-footer>