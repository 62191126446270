<div *ngIf="breadcrumb.type == 'normal';else showBreadcrumbCompra">
  <div class="bg-dark py-4">
    <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
      <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
            <li class="breadcrumb-item">
              <a class="text-nowrap text-white" routerLink="/"><i class="fas fa-home mr-2"></i>Home</a>
            </li>
            <li class="breadcrumb-item text-nowrap text-white" *ngFor="let item of breadcrumb.items">
              <a class="text-white" *ngIf="item.routerlink !='';else showItemActive" routerLink="{{item.routerlink}}">{{item.title}}</a>
              <ng-template #showItemActive>{{item.title}}</ng-template>
            </li>
            
          </ol>
        </nav>
      </div>
      <div class="order-lg-1 pr-lg-4 text-center text-lg-left d-lg-flex align-items-center">
        <h1 class="h3 text-light mb-0">{{breadcrumb.title}} <span class="h4 font-weight-normal text-light" *ngIf="breadcrumb.tracking">34VB5540K83</span></h1>
      </div>
    </div>
  </div>
</div>
<ng-template #showBreadcrumbCompra>
  <div class="bg-dark page-title-overlap pt-4">
    <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li class="breadcrumb-item"><a class="text-nowrap text-white" routerLink="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                <li class="breadcrumb-item text-nowrap text-white" *ngFor="let item of breadcrumb.items">
                  <a class="text-white" *ngIf="item.routerlink !='';else showItemActive" routerLink="{{item.routerlink}}">{{item.title}}</a>
                  <ng-template #showItemActive>{{item.title}}</ng-template>
                </li>
              </ol>
            </nav>
          </div>

          <div class="order-lg-1 pr-lg-4 text-center text-lg-left d-flex align-items-center">
            <h1 class="h3 text-white mb-0">{{breadcrumb.title}}</h1>
          </div>
    </div>
  </div>
</ng-template>
