import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  api: string = 'http://localhost:3443';
  constructor(
    private http: HttpClient,
  ) { }
  getAddress(id:string){
    const path=`${this.api}/address/${id}`;
    return this.http.get<any>(path);
  }
  postAddress(data:any){
    const path=`${this.api}/address/post-address`;
    return this.http.post<any>(path,data);
  }
  putAddress(data:any){
    const path=`${this.api}/address/put-address`;
    return this.http.put<any>(path,data);
  }
  deleteAddress(data:any){
    const path=`${this.api}/address/delete-address`;
    return this.http.put<any>(path,data);
  }
  changePassword(data:any){
    const path=`${this.api}/change-password`;
    return this.http.put<any>(path,data);
  }
}
