<div class="row">
    <div class="col-lg-7 pr-lg-0">
       
    </div>
    <div class="col-lg-5 pt-4 pt-lg-0 cz-image-zoom-pane">
      <div class="product-details ml-auto pb-3">
        <div class="mb-2">
          <ng-rating-bar class="rating-card" [value]="4" [ratingCount]="5" [disabled]="true"  colorActive="#fea569" colorDefault="#8c98a4"></ng-rating-bar>
          <span class="d-inline-block font-size-sm text-body align-middle mt-1 ml-1">74 Comentarios</span>
        </div>
        <div class="h3 font-weight-normal text-accent mb-3 mr-1">$124.<small>99</small></div>
        <div class="font-size-sm mb-4">
          <span class="text-heading font-weight-medium mr-1">Color:</span>
          <span class="text-muted">Dark blue/Orange</span>
        </div>
        <div class="position-relative mr-n4 mb-3">
          <div class="custom-control custom-option custom-control-inline mb-2">
            <input class="custom-control-input" type="radio" name="color" id="color1" checked="">
            <label class="custom-option-label rounded-circle" for="color1"><span class="custom-option-color rounded-circle" style="background-color: #f25540;"></span></label>
          </div>
          <div class="custom-control custom-option custom-control-inline mb-2">
            <input class="custom-control-input" type="radio" name="color" id="color2">
            <label class="custom-option-label rounded-circle" for="color2"><span class="custom-option-color rounded-circle" style="background-color: #65805b;"></span></label>
          </div>
          <div class="custom-control custom-option custom-control-inline mb-2">
            <input class="custom-control-input" type="radio" name="color" id="color3">
            <label class="custom-option-label rounded-circle" for="color3"><span class="custom-option-color rounded-circle" style="background-color: #f5f5f5;"></span></label>
          </div>
          <div class="custom-control custom-option custom-control-inline mb-2">
            <input class="custom-control-input" type="radio" name="color" id="color4">
            <label class="custom-option-label rounded-circle" for="color4"><span class="custom-option-color rounded-circle" style="background-color: #333;"></span></label>
          </div>
          <div class="product-badge product-available mt-n1"><i class="czi-security-check"></i>Product available</div>
        </div>
        <div class="d-flex align-items-center pt-2 pb-4">
          <select class="custom-select mr-3" style="width: 5rem;">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <button class="btn btn-primary btn-shadow btn-block" type="button"><i class="fas fa-cart-plus font-size-lg mr-2"></i>Agregar al carro</button>
        </div>
        <div class="d-flex mb-4">
          <div class="w-100 mr-3">
            <button class="btn btn-secondary btn-block" type="button"><i class="far fa-heart font-size-lg mr-2"></i><span class="d-none d-sm-inline">Agregar a la </span>Lista de Deseo</button>
          </div>
          <div class="w-100">
            <button class="btn btn-secondary btn-block" type="button"><mat-icon class="font-size-lg mr-2">repeat</mat-icon>Comparar</button>
          </div>
        </div>
        <h5 class="h6 mb-3 py-2 border-bottom"><i class="fas fa-exclamation-circle text-muted font-size-lg align-middle mt-n1 mr-2"></i>Información del producto</h5>
        <h6 class="font-size-sm mb-2">General</h6>
        <ul class="font-size-sm pb-2">
          <li><span class="text-muted">Model: </span>Amazfit Smartwatch</li>
          <li><span class="text-muted">Gender: </span>Unisex</li>
          <li><span class="text-muted">OS campitibility: </span>Android / iOS</li>
        </ul>
        <h6 class="font-size-sm mb-2">Especificaciones físicas</h6>
        <ul class="font-size-sm pb-2">
          <li><span class="text-muted">Shape: </span>Rectangular</li>
          <li><span class="text-muted">Body material: </span>Plastics / Ceramics</li>
          <li><span class="text-muted">Band material: </span>Silicone</li>
        </ul>
      </div>
    </div>
  </div>
