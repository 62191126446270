
  
  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" touch="true" keyboard="true">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleControls" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleControls" data-slide-to="1"></li>
      <li data-target="#carouselExampleControls" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active" data-interval="10000">
        <img src="assets/image/slider/1.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item" data-interval="10000">
        <img src="assets/image/slider/2.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item" data-interval="10000">
        <img src="assets/image/slider/3.png"class="d-block w-100" alt="...">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>