import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
///////////// services //////////////////////////////////////
import { TokenInterceptorService } from './services/token-interceptor.service';
import { AdminInterceptorService } from './services/admin-interceptor.service';
///////////////// guards ////////////////////////////////
import { AuthGuard } from './guards/auth.guard';
////////////////// pipes ///////////////////////////////
import { PaginacionPipe } from './pipes/paginacion.pipe';
import { MarcasPipe } from './pipes/marcas.pipe';
import { ColorPipe } from './pipes/color.pipe';
import { CategoriaPipe } from './pipes/categoria.pipe';
import { PreciosPipe } from './pipes/precios.pipe';
import { MedidasPipe } from './medidas.pipe';
///// module /////
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { NgRatingBarModule } from 'ng-rating-bar';
import { MaterialModule } from './modules/material/material.module';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxsModule } from '@ngxs/store';
////// ng prime /////////////////////
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { SliderModule } from 'primeng/slider';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { GalleriaModule } from 'primeng/galleria';
import { CarouselModule } from 'primeng/carousel';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { PaginatorModule } from 'primeng/paginator';
import { SkeletonModule } from 'primeng/skeleton';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { PdfViewerModule } from 'ng2-pdf-viewer';
///// component /////
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { SliderComponent } from './components/slider/slider.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { ProductosDetallesComponent } from './pages/productos-detalles/productos-detalles.component';
import { CartComponent } from './pages/cart/cart.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CategoriaComponent } from './pages/categoria/categoria.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ModalComponent } from './components/modal/modal.component';
import { ProductosDetailsComponent } from './pages/productos-details/productos-details.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { CatalogoComponent } from './pages/catalogo/catalogo.component';
import { TrackingComponent } from './pages/tracking/tracking.component';
import { TrackingDetailsComponent } from './pages/tracking-details/tracking-details.component';
import { CheckoutDetailsComponent } from './pages/checkout-details/checkout-details.component';
import { StepsComponent } from './components/steps/steps.component';
import { AsideComponent } from './components/aside/aside.component';
import { CheckoutShippingComponent } from './pages/checkout-shipping/checkout-shipping.component';
import { CheckoutPaymentComponent } from './pages/checkout-payment/checkout-payment.component';
import { CheckoutReviewComponent } from './pages/checkout-review/checkout-review.component';
import { CheckoutCompleteComponent } from './pages/checkout-complete/checkout-complete.component';
import { GoToComponent } from './components/go-to/go-to.component';
import { AccountWishlistComponent } from './account/pages/account-wishlist/account-wishlist.component';
import { AccountOrdersComponent } from './account/pages/account-orders/account-orders.component';
import { AccountTicketsComponent } from './account/pages/account-tickets/account-tickets.component';
import { AccountProfileComponent } from './account/pages/account-profile/account-profile.component';
import { AccountAddressComponent } from './account/pages/account-address/account-address.component';
import { AccountPaymentComponent } from './account/pages/account-payment/account-payment.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AccountSegurityComponent } from './account/pages/account-segurity/account-segurity.component';
import { CatalogoDetailsComponent } from './pages/catalogo-details/catalogo-details.component';
import { AcercaComponent } from './pages/acerca/acerca.component';
import { RutPipe } from './pipes/rut.pipe';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    SliderComponent,
    ContactoComponent,
    ProductosComponent,
    ProductosDetallesComponent,
    CartComponent,
    BreadcrumbComponent,
    CategoriaComponent,
    SidebarComponent,
    ToolbarComponent,
    ModalComponent,
    ProductosDetailsComponent,
    DialogComponent,
    CatalogoComponent,
    TrackingComponent,
    TrackingDetailsComponent,
    CheckoutDetailsComponent,
    StepsComponent,
    AsideComponent,
    CheckoutShippingComponent,
    CheckoutPaymentComponent,
    CheckoutReviewComponent,
    CheckoutCompleteComponent,
    GoToComponent,
    AccountWishlistComponent,
    AccountOrdersComponent,
    AccountTicketsComponent,
    AccountProfileComponent,
    AccountAddressComponent,
    AccountPaymentComponent,
    PaginacionPipe,
    MarcasPipe,
    ColorPipe,
    CategoriaPipe,
    PreciosPipe,
    MedidasPipe,
    AvatarComponent,
    SpinnerComponent,
    AccountSegurityComponent,
    CatalogoDetailsComponent,
    AcercaComponent,
    RutPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgRatingBarModule,
    SliderModule,
    CheckboxModule,
    InputTextModule,
    NgxSpinnerModule,
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
    MatCarouselModule.forRoot(),
    GalleriaModule,
    CarouselModule,
    CardModule,
    ToastModule,
    ProgressSpinnerModule,
    RippleModule,
    MessagesModule,
    MessageModule,
    ScrollTopModule,
    PaginatorModule,
    AvatarModule,
    AvatarGroupModule,
    SidebarModule,
    TooltipModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    SkeletonModule,
    PasswordModule,
    DividerModule,
    NgxsModule,
    PdfViewerModule
  ],
  exports:[IvyCarouselModule,SliderModule,CheckboxModule,InputTextModule,GalleriaModule],
  providers: [
    AuthGuard,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptorService,
      multi:true
    },
    {
      provide:HTTP_INTERCEPTORS,
      useClass:AdminInterceptorService,
      multi:true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents:[ModalComponent]
})
export class AppModule { }
