import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { debounce, debounceTime } from 'rxjs/operators';

import { AuthService } from '../../../services/auth.service';
import { ApiService } from '../../../services/api.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.css']
})
export class AccountProfileComponent implements OnInit {
  type:string='left';
  formProfile:FormGroup;
  userInfo:any;
  breadcrumbitems={
    type:'product',
    title:'Información de perfil',
    items:[{
      title:"Cuenta",
      routerlink:'/account-profile'
    },
    {
      title:'Información de perfil',
      routerLink:''
    }
    ]
  }
  constructor(
    private formBuilder:FormBuilder,
    private apiService:ApiService,
    public authService:AuthService,
    ) {
    this.formProfile = this.formBuilder.group({
      nombre:['',Validators.required],
      apellido:['',Validators.required],
      telefono:['',Validators.required],
      email:['',Validators.required],
      descripcion:[''],
      userNewsletter:[false]
    });
    this.formProfile.valueChanges.pipe(debounceTime(500)).subscribe(result=>{
      console.log(result);
    })  
  }

  ngOnInit(): void {
    if(this.authService.loggedIn()){
      this.userInfo = this.authService.getUserInfo();
      this.initForm(this.userInfo);
    }
  }
  initForm(user:any){
    this.formProfile.patchValue({
      nombre:user.nombre,
      apellido:user.apellido,
      telefono:user.telefono,
      email:user.email,
      userNewsletter:user.userNewsletter,
      descripcion:user.descripcion
    })
  }
  onPutProfile(){
    let data=this.formProfile.value;
    data.token = this.authService.getToken();
    this.apiService.putUserProfile(data).subscribe(result=>{
      if(result.res){
        Swal.fire(
          'Usuario Editado!',
          '',
          'success'
        )
      }else{
        Swal.fire(
          'Error!',
          'error al editar usuario',
          'error'
        )
      }
    });

  }
  

}
