<div class="steps steps-light pt-2 pb-3 mb-5">
    <a class="step-item active" routerLink="/cart">
        <div class="step-progress">
            <span class="step-count">1</span>
        </div>
        <div class="step-label">
            <i class="czi-cart fas fa-shopping-cart mr-2"></i>Carro
        </div>
    </a>
    <a class="step-item" [ngClass]="{'active': numberActive>=2, 'current': numberActive==2}" routerLink="/checkout-details">
        <div class="step-progress">
            <span class="step-count">2</span>
        </div>
        <div class="step-label">
            <i class="czi-user-circle far fa-user-circle mr-2"></i>Tus detalles
        </div>
    </a>
    <a class="step-item" routerLink="/checkout-shipping" [ngClass]="{'active': numberActive>=3, 'current': numberActive==3,'pointer-events-none':numberActive<3}">
        <div class="step-progress">
            <span class="step-count">3</span>
        </div>
        <div class="step-label">
            <i class="czi-package fas fa-box-open mr-2"></i>Envío
        </div>
    </a>
    <a class="step-item" routerLink="/checkout-payment" [ngClass]="{'active': numberActive>=4, 'current': numberActive==4,'pointer-events-none':numberActive<4}">
        <div class="step-progress">
            <span class="step-count">4</span>
        </div>
        <div class="step-label">
            <i class="czi-card far fa-credit-card mr-2"></i>Pago
        </div>
    </a>
    <a class="step-item" routerLink="/checkout-review" [ngClass]="{'active': numberActive>=5, 'current': numberActive==5,'pointer-events-none':numberActive<5}">
        <div class="step-progress">
            <span class="step-count">5</span>
        </div>
        <div class="step-label">
            <i class="czi-check-circle far fa-check-circle mr-2"></i>Orden completa
        </div>
    </a>
</div>
