<app-navbar></app-navbar>
<main>
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container pb-5 mb-2 mb-md-4 page-title-overlapis">
        <div class="row">
            <app-aside [type]="type" class="col-lg-4"></app-aside>
            <section class="col-lg-8">
                <div class="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
                    <h6 class="font-size-base text-light mb-0">Actualice los detalles de su perfil a continuación:</h6>
                    <button class="btn btn-purple btn-sm d-none d-lg-inline-block" (click)="authService.logout()"><i class="czi-sign-out fas fa-sign-in-alt mr-2"></i>Cerrar sesion</button>
                </div>
                <div class="container">
                    <form [formGroup]="formProfile" (submit)="onPutProfile()">
                        <div class="bg-secondary rounded-lg p-4 mb-4">
                          <div class="media align-items-center">
                              <img *ngIf="userInfo.avatar" class="rounded-circle" src="{{userInfo.avatar}}" alt="Seline Store" width="90">
                            <div class="media-body pl-3">
                              <button class="btn btn-light btn-shadow btn-sm mb-2" type="button"><i class="czi-loading mr-2"></i>Cambiar avatar</button>
                              <div class="p mb-0 font-size-ms text-muted">Subir JPG, GIF o PNG imagen. 300 x 300 requerido.</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="account-fn">Nombre</label>
                              <input class="form-control" type="text" formControlName="nombre">
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="account-ln">Apellido</label>
                              <input class="form-control" type="text" formControlName="apellido"  >
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="account-email">Email</label>
                              <input class="form-control" type="email" formControlName="email"  readonly="">
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="account-phone">Teléfono</label>
                              <input class="form-control" type="text"  formControlName="telefono" >
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="descripcion">Descripción</label>
                              <textarea class="form-control" id="descripcion" formControlName="descripcion"></textarea>
                            </div>
                          </div>
                         
                          <div class="col-12">
                            <hr class="mt-2 mb-3">
                            <div class="d-flex flex-wrap justify-content-between align-items-center">
                              <div class="custom-control custom-checkbox d-block">
                                <input class="custom-control-input" type="checkbox" id="subscribe_me" formControlName="userNewsletter">
                                <label class="custom-control-label" for="subscribe_me">Subscribete a las ultimas novedades</label>
                              </div>
                              <button class="btn btn-purple mt-3 mt-sm-0" type="submit" [disabled]="!formProfile.valid">Actualización del perfil</button>
                            </div>
                          </div>
                        </div>
                      </form>
                </div>
                
            </section>
        </div>
    </div>
    
</main>
<app-footer></app-footer>
