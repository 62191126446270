import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, Validators} from '@angular/forms';
import { debounce, debounceTime } from 'rxjs/operators';
///// material ui ////
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
/////////////// services ////////////////////
import { AuthService } from '../../../services/auth.service';
import { ApiService } from '../../../services/api.service';
import { UserService } from '../../../services/user.service';
////////////////////////// success /////////////////
import Swal from 'sweetalert2'
import { sweetAlertBasic } from '../../../utils/sweet-alert';

@Component({
  selector: 'app-account-address',
  templateUrl: './account-address.component.html',
  styleUrls: ['./account-address.component.css']
})
export class AccountAddressComponent implements OnInit {
  formDireccion:FormGroup;
  formDireccionEdit:FormGroup;
  regiones:any;
  comunas:any;
  displayedColumns: string[] = ['direccion','accion'];
  dataSource = new MatTableDataSource();
  pageSize = 10;
  type:string='left';
  token:string='';
  //formSegurity:FormGroup;
  userInfo:any;
  breadcrumbitems={
    type:'product',
    title:'Información de perfil',
    items:[{
      title:"Cuenta",
      routerlink:'/account-address'
    },
    {
      title:'Dirección',
      routerLink:''
    }
    ]
  }

  constructor(
    private formBuilder:FormBuilder,
    private apiService:ApiService,
    public authService:AuthService,
    private userService:UserService
  ) { 
    this.formDireccion = this.formBuilder.group({
      nombre:['',Validators.required],
      apellido:['',Validators.required],
      email:['',[Validators.required,Validators.email]],
      telefono:['',Validators.required],
      direccion:['',Validators.required],
      direccion2:['',Validators.required],
      region:['',Validators.required],
      comuna:['',Validators.required],
      direccionprimary:[false]
    });

    this.formDireccion.valueChanges.pipe(debounceTime(500)).subscribe(value=>{
      if(value.region!=""){
        this.regiones.forEach((element:any) => {
          if(element.nombre==value.region){
            this.comunas=element.comunas;
          }
        });
      }
    })


    this.formDireccionEdit = this.formBuilder.group({
      _id:['',Validators.required],
      nombre:['',Validators.required],
      apellido:['',Validators.required],
      email:['',[Validators.required,Validators.email]],
      telefono:['',Validators.required],
      direccion:['',Validators.required],
      direccion2:['',Validators.required],
      region:['',Validators.required],
      comuna:['',Validators.required],
      direccionprimary:[false]
    });

    this.formDireccionEdit.valueChanges.pipe(debounceTime(500)).subscribe(value=>{

      if(value.region!=""){
        this.regiones.forEach((element:any) => {
          if(element.nombre==value.region){
            this.comunas=element.comunas;
          }
        });
      }
    })
  }

  ngOnInit(): void {
    if(this.authService.loggedIn()){
      this.token = this.authService.getToken();
      this.getAddress(this.token);
      this.getRegiones();
    }
  }

  submitDireccion(){
    let value=this.formDireccion.value;
    const token=this.authService.getToken();
    value.id = (this.authService.decodeToken(token))._id;
    
    this.userService.postAddress(value).subscribe(result=>{
      if(result.res){
        sweetAlertBasic(result,"success");
        this.getAddress(token);
      }
    })

  }
  getAddress(token:string){
    const id=(this.authService.decodeToken(token))._id;
    this.userService.getAddress(id).subscribe(result=>{
      this.dataSource.data= result.direccion;
    })
  }
  submitDireccionEdit(){
    const value=this.formDireccionEdit.value;
    const token=this.authService.getToken();
    value.id = (this.authService.decodeToken(token))._id;
    this.userService.putAddress(value).subscribe(result=>{
      if(result.res){
        sweetAlertBasic(result,"success");
        this.getAddress(token);
      }
    })
    
  }
  onEditAddress(element:any){
    this.formDireccionEdit.patchValue({
      _id:element._id,
      nombre:element.nombre,
      apellido:element.apellido,
      email:element.email,
      telefono:element.telefono,
      direccion:element.direccion,
      direccion2:element.direccion2,
      region:element.region,
      comuna:element.comuna,
      direccionprimary:element.direccionprimary
    })
  }
  onDeleteAddress(element:any){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger mr-2'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Estas seguro de querer eliminar la siguiente dirección?',
      text: "También se eliminarán de las lista!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Borrar!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.userService.deleteAddress(element).subscribe(result=>{
          if(result.res){
            sweetAlertBasic(result,"success");
            this.getAddress(this.token);
          }else{
            sweetAlertBasic(result,"error");
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
       
      }
    })

  }
  getRegiones(){
    this.apiService.getRegiones().subscribe(result=>{
      this.regiones=result;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
