<app-navbar></app-navbar>
<main id="main" role="main">
    <div class="tracking-header-banner">
        <h1 class="logo">Seguimiento de Compra</h1>
        <span class="bg"></span>
        <img class="sun" src="assets/image/svg/sun.svg">
        <img class="plane" src="assets/image/svg/plane.svg">
        <img class="blimp" src="assets/image/svg/blimp.svg">
        <img class="car" src="assets/image/svg/car.svg">
    </div>

    <div class="container py-4">
        <div class="row">
            <div class="col-lg-5 mx-auto">
                <div class="card border-0 shadow">
                    <div class="card-body">
                        <form >
                            <div class="form-group">
                                <label for="orderId">Número de orden de compra</label>
                                <input type="text" class="form-control">
                            </div>
                            <button class="btn-block btn-purple" mat-raised-button>Ver Seguimiento de tu compra</button>
                        </form>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>