import { Component, OnInit,Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit,OnChanges {
  @Input() token:any;
  @Input() numberItemsWishlist:number=0;
  numberItems: number = 0;
  amountCart: number = 0;
  data:any;
  user:any;
  iniciales:string='';
  userInfo:any;
  isAdmin:any=null;
  constructor(
    private apiService:ApiService,
    public authService:AuthService
    ) { }

  ngOnInit(): void {
   
    if (typeof(Storage) !== "undefined") {
      
      this.token=localStorage.getItem("token1");
   
      if(this.token!=null){
        this.getTokenCart(this.token);
      }

      if(this.authService.loggedIn()){
        this.user = this.authService.getToken();
        this.userInfo = this.authService.getUserInfo();
        this.iniciales = (`${this.userInfo.nombre.charAt(0)}${this.userInfo.apellido.charAt(0)}`).toLocaleUpperCase();
        this.getWishlist(this.user);
        this.authService.isAdmin$.subscribe(result=>{
          this.isAdmin = result;
        }) 
      }
      
    
    } 

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("hay cambio");
    
    if(this.token!=null && this.token!=undefined){
      this.getTokenCart(this.token);
    }
   
  }
  getWishlist(user:string){
    this.apiService.getWishlist(user).subscribe(result=>{
      console.log(result);
      this.numberItemsWishlist=result.items.length;
    })
  }

  getTokenCart(token:any){
    this.apiService.getCart(token).subscribe(result=>{
      this.data = result;
      this.numberItems = result.cantidad;
      this.amountCart = result.totalformateado;
      if(result.cantidad==0){
        localStorage.removeItem('token');
        localStorage.removeItem('token1');
      } 
    })
  }
  deleteItems(id:string){
    const datos={
      token:this.token,
      id:id
    };
    this.apiService.deleteCartItems(datos).subscribe(result=>{
      if(result.res){
        localStorage.setItem('token',result.token);
        this.token=result.token;
        this.getTokenCart(this.token);
      }
    });
  }
  
 

}
