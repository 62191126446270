<app-navbar></app-navbar>
<main>
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container pb-5 mb-2 mb-md-4 page-title-overlapis" *ngIf="data">
        <div class="row">
            <section class="col-lg-8">
                <app-steps [numberActive]="5"></app-steps>
                <h2 class="h6 pb-3 mb-2">Revise su orden</h2>

                <div >
                    <div class="d-sm-flex justify-content-between my-4 pb-3 border-bottom" *ngFor="let item of data.items">
                        <div class="media media-ie-fix d-block d-sm-flex text-center text-sm-left">
                          <a class="d-inline-block mx-auto mr-sm-4" href="shop-single-v1.html" style="width: 10rem;">
                            <img src="{{item.img}}" alt="Product">
                          </a>
                          <div class="media-body pt-2">
                            <h3 class="product-title font-size-base mb-2"><a href="shop-single-v1.html">{{item.titulo}}</a></h3>
                            <div class="font-size-sm"><span class="text-muted mr-2">Medidas:</span>{{item.medidas}}</div>
                            <div class="font-size-sm"><span class="text-muted mr-2">Color:</span>{{item.color}}</div>
                            <div class="font-size-lg text-accent pt-2">$154.<small>00</small></div>
                          </div>
                        </div>
                        <div class="pt-2 pt-sm-0 pl-sm-3 mx-auto mx-sm-0 text-center text-sm-right" style="max-width: 9rem;">
                          <p class="mb-0"><span class="text-muted font-size-sm mr-2">Cantidad:</span><span>{{item.cantidad}}</span></p>
                          
                        </div>
                    </div>
                </div>

                <div class="bg-secondary rounded-lg px-4 pt-4 pb-2">
                    <div class="row">
                      <div class="col-sm-6">
                        <h4 class="h6">Dirección de facturación:</h4>
                        <ul class="list-unstyled font-size-sm">
                          <li><span class="text-muted">Cliente:&nbsp;</span>Susan Gardner</li>
                          <li><span class="text-muted">Dirección:&nbsp;</span>44 Shirley Ave. West Chicago, IL 60185, USA</li>
                          <li><span class="text-muted">Telefono:&nbsp;</span>+1 (808) 764 554 330</li>
                        </ul>
                      </div>
                      <div class="col-sm-6">
                        <h4 class="h6">Método de pago:</h4>
                        <ul class="list-unstyled font-size-sm">
                          <li><span class="text-muted">Tarjeta de crédito:&nbsp;</span>**** **** **** 5300</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                <div class="d-none d-lg-flex pt-4">
                    <div class="w-50 pr-3">
                        <a class="btn btn-secondary btn-block" routerLink="/checkout-payment">
                            <i class="czi-arrow-left fas fa-chevron-left mt-sm-0 mr-1"></i>
                            <span class="d-none d-sm-inline">Volver a Pago</span>
                            <span class="d-inline d-sm-none">Volver</span>
                        </a>
                    </div>
                    <div class="w-50 pl-2">
                        <a class="btn btn-purple btn-block" routerLink="/checkout-complete">
                            <span class="d-none d-sm-inline">Orden completa</span>
                            <span class="d-inline d-sm-none">Siguiente</span><i class="czi-arrow-right fas fa-chevron-right mt-sm-0 ml-1"></i>
                        </a>
                    </div>
                  </div>
            </section>
           <app-aside [type]="type" class="col-lg-4 pt-4 pt-lg-0"></app-aside>
        </div>
    </div>
</main>
<app-footer></app-footer>