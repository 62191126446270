<app-navbar></app-navbar>
<main>
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container pb-5 mb-2 mb-md-4 page-title-overlapis">
        <div class="row">
            <app-aside [type]="type" class="col-lg-4"></app-aside>
            <section class="col-lg-8">
                <div class="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
                    <h6 class="font-size-base text-light mb-0">Lista de sus direcciones registradas:</h6>
                    <button class="btn btn-purple btn-sm d-none d-lg-inline-block" (click)="authService.logout()"><i class="czi-sign-out fas fa-sign-in-alt mr-2"></i>Cerrar sesion</button>
                </div>
                <div class="container">

                    <div class="w-100">
                        <div class="d-flex justify-content-end">
                          <mat-form-field appearance="fill" (keyup)="applyFilter($event)">
                            <mat-label>Buscar</mat-label>
                            <input matInput>
                          </mat-form-field>
                        </div>
                    </div>
                    <table class="w-100" mat-table [dataSource]="dataSource" matSort>
                    
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="direccion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Dirección</th>
                            <td mat-cell *matCellDef="let element">{{element.direccioncompleta}}<span *ngIf="element.direccionprimary" class="align-middle badge bg-info ml-2 text-white">Primaria</span></td>
                        </ng-container>
                    
           
                         <ng-container matColumnDef="accion">
                            <th mat-header-cell *matHeaderCellDef>Accíon</th>
                            <td mat-cell *matCellDef="let element wrap">
                              <div class="d-flex">
                                <button mat-icon-button matTooltip="Editar Dirección"  data-toggle="modal" data-target="#exampleModal1" (click)="onEditAddress(element)"><mat-icon>edit</mat-icon></button>
                                <button mat-icon-button matTooltip="Eliminar Dirección" class="text-danger"  (click)="onDeleteAddress(element)"><mat-icon>delete</mat-icon></button>    
                              </div>
                            </td>
                          </ng-container>
                    
                       
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                      <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
                    
                </div>

                <div class="text-right pt-4">
                    <a class="btn btn-purple" href="javascript:void(0);" data-toggle="modal" data-target="#exampleModal">Agregar nueva Dirección</a>

                </div>
                
            </section>
        </div>
    </div>
    
</main>
<app-footer></app-footer>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-target="#exampleModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Agregar direccíon</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form [formGroup]="formDireccion" (submit)="submitDireccion()">
        <div class="modal-body">
          
            <div>
                  <div class="form-row">

                      <div class="col-sm-6">
                          <div class="form-group">
                            <label for="checkout-fn">Nombres</label>
                            <input class="form-control" type="text" formControlName="nombre">
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                            <label for="checkout-fn">Apellidos</label>
                            <input class="form-control" type="text" formControlName="apellido">
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                            <label for="checkout-fn">Email</label>
                            <input class="form-control" type="email" formControlName="email">
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                            <label for="checkout-fn">Telefono</label>
                            <input class="form-control" type="text" formControlName="telefono">
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                            <label for="checkout-fn">Dirección 1</label>
                            <input class="form-control" type="text" formControlName="direccion">
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                            <label for="checkout-fn">Dpto. / Casa / Oficina / Condominio (opcional)</label>
                            <input class="form-control" type="text" formControlName="direccion2">
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                            <label for="checkout-country">Región</label>
                            <select class="form-control custom-select" formControlName="region">
                              <option value="">Seleccione una Región</option>
                              <option *ngFor="let region of regiones">{{region.nombre}}</option>
                            </select>
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                            <label for="checkout-country">Comuna</label>
                            <select class="form-control custom-select"  formControlName="comuna">
                              <option value="">Seleccione una comuna</option>
                              <ng-container *ngIf="comunas">
                                <option *ngFor="let comuna of comunas">{{comuna.name}}</option>
                              </ng-container>
                              
                            </select>
                          </div>
                      </div>

                  </div>
              
          </div>
          <h6 class="mb-3 py-3 border-bottom">Dirección de Envio</h6>
          <div>
              <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" type="checkbox" formControlName="direccionprimary" id="same-address">
                  <label class="custom-control-label" for="same-address">Usar como direccíon primaria</label>
              </div>
          </div>
         
        </div>
        <div class="modal-footer">
          <button class="btn btn-purple" type="submit" [disabled]="!formDireccion.valid">
            <i class="czi-arrow-right fas fa-map-marker-alt mt-sm-0 mr-1"></i>
            Guardar direccíon
          </button>
        </div>
      </form>
      </div>
    </div>
</div>

<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true" data-target="#exampleModal1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">Editar direccíon</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="formDireccionEdit" (submit)="submitDireccionEdit()">
      <div class="modal-body">
        
          <div>
                <div class="form-row">

                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Nombres</label>
                          <input class="form-control" type="text" formControlName="nombre">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Apellidos</label>
                          <input class="form-control" type="text" formControlName="apellido">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Email</label>
                          <input class="form-control" type="email" formControlName="email">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Telefono</label>
                          <input class="form-control" type="text" formControlName="telefono">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Dirección 1</label>
                          <input class="form-control" type="text" formControlName="direccion">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Dpto. / Casa / Oficina / Condominio (opcional)</label>
                          <input class="form-control" type="text" formControlName="direccion2">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-country">Región</label>
                          <select class="form-control custom-select" formControlName="region">
                            <option value="">Seleccione una Región</option>
                            <option *ngFor="let region of regiones">{{region.nombre}}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-country">Comuna</label>
                          <select class="form-control custom-select"  formControlName="comuna">
                            <option value="">Seleccione una comuna</option>
                            <ng-container *ngIf="comunas">
                              <option *ngFor="let comuna of comunas">{{comuna.name}}</option>
                            </ng-container>
                            
                          </select>
                        </div>
                    </div>

                </div>
            
        </div>
        <h6 class="mb-3 py-3 border-bottom">Dirección de Envio</h6>
        <div>
            <div class="custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" formControlName="direccionprimary" id="same-address1">
                <label class="custom-control-label" for="same-address1">Usar como direccíon primaria</label>
            </div>
        </div>
       
      </div>
      <div class="modal-footer">
        <button class="btn btn-purple" type="submit" [disabled]="!formDireccionEdit.valid">
          <i class="czi-arrow-right fas fa-map-marker-alt mt-sm-0 mr-1"></i>
          Guardar direccíon
        </button>
      </div>
    </form>
    </div>
  </div>
</div>