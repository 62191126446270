<app-navbar></app-navbar>
<main>
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container pb-5 mb-2 mb-md-4 page-title-overlapis">
        <div class="row">
            <app-aside [type]="type" class="col-lg-4"></app-aside>
            <section class="col-lg-8">
                <div class="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
                    <h6 class="font-size-base text-light mb-0">Actualizar Contraseña:</h6>
                    <button class="btn btn-purple btn-sm d-none d-lg-inline-block" (click)="authService.logout()"><i class="czi-sign-out fas fa-sign-in-alt mr-2"></i>Cerrar sesion</button>
                </div>
                <div class="container">
                    <form [formGroup]="formSegurity" (submit)="onChangePassword()">
                        
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="account-fn">Contraseña</label>
                              <input class="form-control" type="password" formControlName="password">
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label for="account-ln">Confirmar Contraseña</label>
                              <input class="form-control" type="password" formControlName="confirmarPassword">
                              <mat-error class="mat-error" *ngIf="formSegurity.errors?.noSonIguales && (formSegurity.touched || formSegurity.dirty)">Las contraseñas no coinciden</mat-error>
                            </div>
                          </div>
                       
                          
                         
                          <div class="col-12">
                            <hr class="mt-2 mb-3">
                            <div class="d-flex flex-wrap justify-content-between align-items-center">
                              <div></div>
                              <button class="btn btn-purple mt-3 mt-sm-0" type="submit" [disabled]="!formSegurity.valid">Actualización del perfil</button>
                            </div>
                          </div>
                        </div>
                      </form>
                </div>
                
            </section>
        </div>
    </div>
    
</main>
<app-footer></app-footer>
