<app-navbar [token]="token1" [numberItemsWishlist]="numberItemsWishlist"></app-navbar>
<main>
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container pb-5 mb-2 mb-md-4 page-title-overlapis">
        <div class="row">
            <app-aside [type]="type" [numberItemsWishlist]="numberItemsWishlist" class="col-lg-4"></app-aside>
            <section class="col-lg-8">
                <div class="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
                    <h6 class="font-size-base text-light mb-0">Lista de elementos que agregó a la lista de deseos:</h6>
                    <button class="btn btn-purple btn-sm d-none d-lg-inline-block" (click)="authService.logout()"><i class="czi-sign-out fas fa-sign-in-alt mr-2"></i>Cerrar sesion</button>
                </div>
                <div class="container" *ngIf="wishlist">
                    <div class="media d-block d-sm-flex align-items-center py-4 border-bottom" *ngFor="let products of wishlist">
                        <div class="d-block position-relative mb-3 mb-sm-0 mr-sm-4 mx-auto" style="width: 12.5rem;">
                            <a routerLink="/productos/{{products._id}}"><img class="rounded-lg" src="{{products.imagenproductos[0]}}" alt="Productos"></a>
                            <span class="close-floating"  matTooltip="Eliminar de la lista de deseo" (click)="removeItem(products)">
                                <i class="czi-close fas fa-times"></i>
                            </span>
                        </div>
                        <div class="media-body text-center text-sm-left">
                          <h3 class="h6 product-title mb-2"><a routerLink="/productos/{{products._id}}">{{products.titulo}})</a></h3>
                          <div class="d-inline-block text-accent">${{products.precioformateado}}.<small>00</small></div>
                          <a class="d-inline-block text-accent font-size-ms border-left ml-2 pl-2" routerLink="/productos/{{products._id}}">{{products.categoria}}</a>
                          <div class="form-inline pt-2">
                            <button class="btn btn-purple btn-sm mx-auto mx-sm-0 my-2" type="button" data-toggle="toast" data-target="#cart-toast" (click)="addCart(products)"><i class="czi-cart mr-1"></i>Agregar al carro de compra</button>
                          </div>
                        </div>
                    </div>
                </div>
                
            </section>
        </div>
    </div>
    
</main>
<app-footer></app-footer>