<app-navbar></app-navbar>
<main>
    <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container pb-5 mb-2 mb-md-4 page-title-overlapis">
        <div class="row">
            <section class="col-lg-8">
                <app-steps [numberActive]="2"></app-steps>
                <div class="d-sm-flex justify-content-between align-items-center bg-secondary p-4 rounded-lg mb-grid-gutter" *ngIf="user">
                    <div class="media align-items-center">
                      <div class="img-thumbnail rounded-circle position-relative" style="width: 6.375rem;">
                        <span class="badge badge-warning" data-toggle="tooltip" title="" data-original-title="Reward points">384</span>
                        <img class="rounded-circle" src="{{user.avatar}}" alt="Seline Store">
                      </div>
                      <div class="media-body pl-3">
                        <h3 class="font-size-base mb-0">{{user.nombre}} {{user.apellido}}</h3>
                        <span class="text-accent font-size-sm">{{user.email}}</span>
                      </div>
                    </div>
                    <a class="btn btn-light btn-sm btn-shadow mt-3 mt-sm-0" routerLink="/account-profile"><i class="czi-edit fas fa-edit mr-2"></i>Editar perfil</a>
                </div>
                <!--shipping address -->
                <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">Dirección de Envío</h2>
                <ng-container *ngIf="!authService.loggedIn();else showDirection">
                  <form [formGroup]="formDireccion" (submit)="submitDireccion()">
                    <div>
                          <div class="form-row">

                              <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="checkout-fn">Nombres</label>
                                    <input class="form-control" type="text" formControlName="nombre">
                                  </div>
                              </div>
                              <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="checkout-fn">Apellidos</label>
                                    <input class="form-control" type="text" formControlName="apellido">
                                  </div>
                              </div>
                              <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="checkout-fn">Email</label>
                                    <input class="form-control" type="email" formControlName="email">
                                  </div>
                              </div>
                              <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="checkout-fn">Telefono</label>
                                    <input class="form-control" type="text" formControlName="telefono">
                                  </div>
                              </div>
                              <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="checkout-fn">Dirección 1</label>
                                    <input class="form-control" type="text" formControlName="direccion">
                                  </div>
                              </div>
                              <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="checkout-fn">Dpto. / Casa / Oficina / Condominio (opcional)</label>
                                    <input class="form-control" type="text" formControlName="direccion2">
                                  </div>
                              </div>
                              <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="checkout-country">Región</label>
                                    <select class="form-control custom-select" formControlName="region">
                                      <option value="">Seleccione una Región</option>
                                      <option *ngFor="let region of regiones">{{region.nombre}}</option>
                                    </select>
                                  </div>
                              </div>
                              <div class="col-sm-6">
                                  <div class="form-group">
                                    <label for="checkout-country">Comuna</label>
                                    <select class="form-control custom-select"  formControlName="comuna">
                                      <option value="">Seleccione una comuna</option>
                                      <ng-container *ngIf="comunas">
                                        <option *ngFor="let comuna of comunas">{{comuna.name}}</option>
                                      </ng-container>
                                      
                                    </select>
                                  </div>
                              </div>

                          </div>
                      
                  </div>
                  <h6 class="mb-3 py-3 border-bottom">Dirección de Envio</h6>
                  <div>
                      <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" checked="" id="same-address">
                          <label class="custom-control-label" for="same-address">La misma que la dirección de envío</label>
                      </div>
                      <div class="d-none d-lg-flex pt-4 mt-3">
                          <div class="w-50 pr-3">
                              <a class="btn btn-secondary btn-block" routerLink="/cart">
                                  <i class="czi-arrow-left fas fa-chevron-left mt-sm-0 mr-1"></i>
                                  <span class="d-none d-sm-inline">Volver al carrito</span>
                                  <span class="d-inline d-sm-none">Volver</span>
                              </a>
                          </div>
                          <div class="w-50 pl-2">
                              <button class="btn btn-purple btn-block" type="submit" [disabled]="!formDireccion.valid">
                                  <span class="d-none d-sm-inline">Proceder al envío</span>
                                  <span class="d-inline d-sm-none">Siguiente</span>
                                  <i class="czi-arrow-right fas fa-chevron-right mt-sm-0 ml-1"></i>
                              </button>
                          </div>
                      </div>
                    </div>
                  </form>
                </ng-container>
                <ng-template #showDirection>
                  <div class="row">
                    <div class="col-sm-6 mb-5 mb-sm-7" *ngFor="let direccion of direction;let i=index">
                      <!-- Radio Checkbox -->
                      <div class="custom-control custom-radio custom-control-inline w-100 h-100" >
                        <input type="radio" id="{{direccion._id}}" name="billingRadio" class="custom-control-input" (click)="selectDirection(direccion)" value="{{direccion._id}}">
                        <label class="custom-control-label" for="{{direccion._id}}">
                          <span class="h5 d-block" >Dirección de Envio #{{i+1}}</span>
    
                          <span class="d-block mb-2">
                            {{direccion.direccioncompleta}}
                          </span>
    
                          <a class="btn btn-xs btn-white" routerLink="/account-address">
                            <i class="fas fa-pencil-alt mr-1"></i> Editar dirección
                          </a>
                        </label>
                      </div>
                      <!-- End Radio Checkbox -->
                    </div>
    
    
                    <div class="col-sm-6 mb-5 mb-sm-7">
                      <!-- Card -->
                      <a class="card card-dashed" href="javascript:;" data-toggle="modal" data-target="#addNewAddressModal">
                        <div class="card-body card-body-centered card-dashed-body">
                          <i class="fas fa-building fa-lg mb-2"></i>
                          Agregar nueva dirección
                        </div>
                      </a>
                      <!-- End Card -->
                    </div>
                  </div>

                  <form (submit)="submitDireccion()">
                    <div class="d-none d-lg-flex pt-4 mt-3">
                      <div class="w-50 pr-3">
                          <a class="btn btn-secondary btn-block" routerLink="/cart">
                              <i class="czi-arrow-left fas fa-chevron-left mt-sm-0 mr-1"></i>
                              <span class="d-none d-sm-inline">Volver al carrito</span>
                              <span class="d-inline d-sm-none">Volver</span>
                          </a>
                      </div>
                      <div class="w-50 pl-2">
                          <button class="btn btn-purple btn-block" type="submit" [disabled]="!formDireccion.valid">
                              <span class="d-none d-sm-inline">Proceder al envío</span>
                              <span class="d-inline d-sm-none">Siguiente</span>
                              <i class="czi-arrow-right fas fa-chevron-right mt-sm-0 ml-1"></i>
                          </button>
                      </div>
                    </div>
                  </form>
                  
                </ng-template>
            
            </section>
           <app-aside [type]="type" class="col-lg-4 pt-4 pt-lg-0" [productos]="data"></app-aside>
        </div>
    </div>
</main>
<app-footer></app-footer>

<div class="modal fade" id="addNewAddressModal" tabindex="-1" aria-labelledby="addNewAddressModalLabel" aria-hidden="true" data-target="#addNewAddressModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addNewAddressModalLabel">Agregar direccíon</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="formDireccion" (submit)="submitDireccionUsers()">
      <div class="modal-body">
        
          <div>
                <div class="form-row">

                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Nombres</label>
                          <input class="form-control" type="text" formControlName="nombre">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Apellidos</label>
                          <input class="form-control" type="text" formControlName="apellido">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Email</label>
                          <input class="form-control" type="email" formControlName="email">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Telefono</label>
                          <input class="form-control" type="text" formControlName="telefono">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Dirección 1</label>
                          <input class="form-control" type="text" formControlName="direccion">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-fn">Dpto. / Casa / Oficina / Condominio (opcional)</label>
                          <input class="form-control" type="text" formControlName="direccion2">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-country">Región</label>
                          <select class="form-control custom-select" formControlName="region">
                            <option value="">Seleccione una Región</option>
                            <option *ngFor="let region of regiones">{{region.nombre}}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                          <label for="checkout-country">Comuna</label>
                          <select class="form-control custom-select"  formControlName="comuna">
                            <option value="">Seleccione una comuna</option>
                            <ng-container *ngIf="comunas">
                              <option *ngFor="let comuna of comunas">{{comuna.name}}</option>
                            </ng-container>
                            
                          </select>
                        </div>
                    </div>

                </div>
            
        </div>
        <h6 class="mb-3 py-3 border-bottom">Dirección de Envio</h6>
        <div>
            <div class="custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" formControlName="direccionprimary" id="same-address">
                <label class="custom-control-label" for="same-address">Usar como direccíon primaria</label>
            </div>
        </div>
       
      </div>
      <div class="modal-footer">
        <button class="btn btn-purple" type="submit" [disabled]="!formDireccion.valid">
          <i class="czi-arrow-right fas fa-map-marker-alt mt-sm-0 mr-1"></i>
          Guardar direccíon
        </button>
      </div>
    </form>
    </div>
  </div>
</div>