import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input() sidebaropen: boolean = false;
  panelOpenState:Boolean= false;
  rangeValues: number[] = [20,80];
  selectedCategories: any[] = ['Technology', 'Sports'];
  categories: any[] = [{name: 'Accounting', key: 'A'}, {name: 'Marketing', key: 'M'}, {name: 'Production', key: 'P'}, {name: 'Research', key: 'F'}];
  sizes: any[] = [{name: '14', key: '14'}, {name: '15', key: '15'}, {name: '16', key: '16'}, {name: '17', key: '17'}];
  items = Array.from({length: 100000}).map((_, i) => `Item #${i}`);
  constructor() { }

  ngOnInit(): void {
  }

}
