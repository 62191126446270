import { Component, OnInit } from '@angular/core';
import {FormGroup,FormBuilder,Validators} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
////////////////////// service ///////////////////////
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.css']
})
export class CheckoutPaymentComponent implements OnInit {
  numberItems:number=0;
  amountCart:number=0;
  token:string | null ='';
  data:any;
  direction:any;
  breadcrumbitems={
    type:'product',
    title:'Revisa',
    items:[{
      title:"Compras",
      routerlink:'/categoria'
    },
    {
      title:'Revisa',
      routerLink:''
    }
    ]
  }
  type:string="right";
  constructor(
    private apiService:ApiService,
    private authService:AuthService,
    private userService:UserService
    ) { }

  ngOnInit(): void {
    if (typeof(Storage) !== "undefined") {
      const direction:any=localStorage.getItem('direction');
      this.token=localStorage.getItem("token1");
      this.direction=JSON.parse(direction);
      if(this.token!=null){
        this.getTokenCart(this.token);
      }
    
    } 
  }
  postWepay(){
    let data:any={
      user:[],
      amount:this.data.total,
      cart:this.data,
      direction:this.direction
    }
    if(this.authService.loggedIn()){
      const token=this.authService.getToken();
      data.user = this.authService.decodeToken(token);
    }
    
    this.apiService.postTokenWepay(data).subscribe(result=>{
      const url=result.url;
      const token=result.token;
      localStorage.setItem('token-webpay',token);
      location.href = url;
      //window.open(url,'_blank');
    });
  }

  getTokenCart(token:string){
    this.apiService.getCart(token).subscribe(result=>{
      this.data = result;
      console.log(this.data);
    });
  }

}
