import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {
  breadcrumbitems={
    type:'normal',
    title:'Rastreo de orden',
    items:[{
      title:"Rastreo de orden",
      routerlink:'/tracking'
    },
    {
      title:"34VB5540K83",
      routerlink:''
    }
    ]
  }
  constructor() { }

  ngOnInit(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

}
