import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-go-to',
  templateUrl: './go-to.component.html',
  styleUrls: ['./go-to.component.css']
})
export class GoToComponent implements OnInit {
  gotoToogle$=false;
  constructor() { }

  ngOnInit(): void {
  }

  @HostListener("window:scroll", ['$event'])
  doSomethingOnWindowsScroll($event:Event){

    const scroll:number=window.scrollY;
    const limit:number=800;
    if(scroll>limit){
      this.gotoToogle$=true;
    }else{
      this.gotoToogle$=false;
    }
   
  }

  goTo(){
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }


}
