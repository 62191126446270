import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { throwError,Observable } from 'rxjs';
/////// interfaz /////////
import { Wishlist } from '../interfaces/wishlist';
import { Catalogo } from '../interfaces/catalogo';
////// class /////////
import { TrackHttpError } from '../shared/models/trackHttpError';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api: string = 'http://localhost:3443';
  constructor(private http: HttpClient) { }
  
  getCatalogo(){
    const path=`${this.api}/catalogo`;
    return this.http.get<Catalogo[]>(path);
  }
  getCatalogoDetails(id:string){
    const path=`${this.api}/catalogo/${id}`;
    return this.http.get<Catalogo>(path);
  }
  postTokenWepay(Data:any){
    //const path=`https://selinestore-php.herokuapp.com/`;
    const path=`http://localhost/selinestore/`;
    return this.http.post<any>(path,Data);
  }
  PostContacto(Data:any){
    const path=`${this.api}/contacto`;
    return this.http.post<any>(path,Data);
  }
  getRegiones(){
    const path=`${this.api}/regiones`;
    return this.http.get<any>(path);
  }

  getImages() {
    return this.http.get<any>('assets/json/data.json')
      .toPromise()
      .then(res => <any>res.data)
      .then(data => { return data; });
  }
  postNewsletterUser(email:any){
    const path=`${this.api}/usersnewsletter`;
    return this.http.post<any>(path,email);
  }
  getCategorias(){
    const path=`${this.api}/categorias`;
    return this.http.get<any>(path);
  }
  getProductos(){
    const path=`${this.api}/productos`;
    return this.http.get<any>(path);
  }
  getProductosHome(){
    const path=`${this.api}/productos-home`;
    return this.http.get<any>(path);
  }
  getProductosDetalles(id:string | null){
    const path=`${this.api}/productos-detalles/${id}`;
    return this.http.get<any>(path);
  }
  getProductosRelacion(categoria:String){
    const path=`${this.api}/productos-relacion/${categoria}`;
    return this.http.get<any>(path);
  }
  getRatings(id:string | null){
    const path=`${this.api}/ratings/${id}`;
    return this.http.get<any>(path);
  }
  postRatings(formData:any){
    const path=`${this.api}/post-ratings`;
    return this.http.post<any>(path,formData);
  }
  postLikeRatings(body:any){
    const path=`${this.api}/post-like-ratings`;
    return this.http.post<any>(path,body);
  }
  postDisLikeRatings(body:any){
    const path=`${this.api}/post-dislike-ratings`;
    return this.http.post<any>(path,body);
  }
  getCart(token:any):Observable<any | TrackHttpError>{
    const path=`${this.api}/cart-get/${token}`;
    return this.http.get<any>(path)
  }
  postCart(body:any){
    const path=`${this.api}/cart-post`;
    return this.http.post<any>(path,body);
  }
  deleteCartItems(body:any){
    const path=`${this.api}/cart-delete-item`;
    return this.http.post<any>(path,body);
  }
  deleteCartAllItems(token:string){
    const path=`${this.api}/cart-delete-item-all/${token}`;
    return this.http.delete<any>(path);
  }
  putCartQualityItem(body:any){
    const path=`${this.api}/cart-put-itemquality`;
    return this.http.put<any>(path,body);
  }
  editCart(body:any){
    const path=`${this.api}/cart-put`;
    return this.http.put<any>(path,body);
  }
  /* user */
  putUserProfile(data:any){
    const path=`${this.api}/profile-edit`;
    return this.http.put<any>(path,data);
  }
  getWishlist(token:string){
    let headers = new HttpHeaders({
      'token': token
    });
    const path=`${this.api}/wishlist`;
    return this.http.get<any>(path,{headers});
  }
  removeWishlist(data:any){
    const path=`${this.api}/wishlist-remove`;
    return this.http.post<any>(path,data);
  }
  postAddwishlist(body:any){
    const path=`${this.api}/wishlist-post`;
    return this.http.post<any>(path,body);
  }

  private handleHttpError(error:HttpErrorResponse):Observable<TrackHttpError>{
    let dataError = new TrackHttpError();
    dataError.errorNumber = error.status;
    dataError.message = error.statusText;
    dataError.friendlyMessage = "Ha ocurrido una restricción de data";
    return throwError(dataError);
  }
  ///////////////// orden ///////////////////
  getLastOrden(token:any){
    const path=`${this.api}/cart-last-orden/${token}`;
    return this.http.get<any>(path);
  }

 

}
