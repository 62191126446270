import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
///////////////////// services ////////////////////
import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
@Component({
  selector: 'app-account-wishlist',
  templateUrl: './account-wishlist.component.html',
  styleUrls: ['./account-wishlist.component.css']
})
export class AccountWishlistComponent implements OnInit {
  numberItemsWishlist:number=0;
  wishlist:any;
  type:string='left';
  user:any;
  token:string | null='';
  token1:string | null='';
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  breadcrumbitems={
    type:'product',
    title:'Mi lista de deseos',
    items:[{
      title:"Cuenta",
      routerlink:'/account-orders'
    },
    {
      title:'Mi lista de deseos',
      routerLink:''
    }
    ]
  }
  constructor(
    private apiService:ApiService,
    public authService:AuthService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    if(this.authService.loggedIn()){
      this.user=this.authService.getToken();
      this.getWishlist(this.user);
    }
  }

  getWishlist(user:string){
    this.apiService.getWishlist(user).subscribe(result=>{
      this.wishlist = result.items;
      this.numberItemsWishlist=result.items.length;
    })
  }
  removeItem(productos:any){

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger mr-2'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: '¿Estas seguro de querer eliminar estos artículos?',
      text: "También se eliminarán de las lista!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Borrar!',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        

        const data={
          token:this.user,
          producto:productos
        }
     
        this.apiService.removeWishlist(data).subscribe(result=>{
          if(result.res){
            swalWithBootstrapButtons.fire(
              'Eliminado!',
              'Eliminado correctamente',
              'success'
            )
            this.getWishlist(this.user);
          }
        })
      } else if (

        result.dismiss === Swal.DismissReason.cancel
      ) {
       
      }
    })
    
  }

  openSnackBar(message:string) {
    this._snackBar.open(message, '', {
      duration: 500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  addCart(element:any){
    
    const cart={
      id:element._id,
      titulo:element.titulo,
      color:element.colores[0].colornombre,
      precio:element.precio,
      precioformateado:element.precioformateado,
      preciounitario:element.precio,
      preciounitarioformateado:element.precioformateado,
      img:element.imagenproductos[0],
      medidas:element.tallas[0],
      cantidad:1,
      url:`/productos/${element._id}`
    }
    if(localStorage.getItem("token")==null){
      this.apiService.postCart(cart).subscribe(result=>{
        if(result.res){
          localStorage.setItem("token",result.data._id);
          localStorage.setItem("token1",result.token);
          this.token=localStorage.getItem("token");
          localStorage.setItem("token1",result.token);
          this.token1=localStorage.getItem("token1");
          this.openSnackBar('Artículo agregado al carro de compra');
        }
      })
    }else{
      const data={
        id:localStorage.getItem("token"),
        data:cart,
        token:localStorage.getItem("token1"),
      }
      this.apiService.editCart(data).subscribe(result=>{
        if(result){
          this.token=localStorage.getItem("token");
          localStorage.setItem("token1",result.token);
          this.token1=localStorage.getItem("token1");
          this.openSnackBar('Artículo agregado al carro de compra');
        }
      });

    }
  
  }

}
