import { Component, OnInit,Input } from '@angular/core';
////////////// services /////////////
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.css']
})
export class AsideComponent implements OnInit {
  @Input() type:any;
  @Input() productos:any;
  @Input() numberItemsWishlist:number=0;
  userInfo:any;
  constructor(
    public authService:AuthService,
    private userService:UserService
  ) { }

  ngOnInit(): void {
    if(this.authService.loggedIn()){
      this.userInfo = this.authService.getUserInfo();
      console.log(this.userInfo);
    }
  }

}
