import { Component, OnInit } from '@angular/core';
import {FormGroup,FormBuilder,Validators} from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { Router,ActivatedRoute } from '@angular/router';
///////////////////// services ///////////////////
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
////////////////////////// success /////////////////
import { sweetAlertBasic } from '../../utils/sweet-alert';

@Component({
  selector: 'app-checkout-details',
  templateUrl: './checkout-details.component.html',
  styleUrls: ['./checkout-details.component.css']
})
export class CheckoutDetailsComponent implements OnInit {
  user:any;
  formDireccion:FormGroup;
  regiones:any;
  comunas:any;
  direction:any;
  numberItems:number=0;
  amountCart:number=0;
  token:string | null ='';
  data:any;
  breadcrumbitems={
    type:'product',
    title:'Revisa',
    items:[{
      title:"Compras",
      routerlink:'/categoria'
    },
    {
      title:'Revisa',
      routerLink:''
    }
    ]
  }
  type:string="right";
  constructor(private formBuilder:FormBuilder,
    private apiService:ApiService,
    public authService:AuthService,
    private userService:UserService,
    private _router:Router,
    private _ac:ActivatedRoute
    ) {
    this.formDireccion = this.formBuilder.group({
      nombre:['',Validators.required],
      apellido:['',Validators.required],
      email:['',[Validators.required,Validators.email]],
      telefono:['',Validators.required],
      direccion:['',Validators.required],
      direccion2:['',Validators.required],
      region:['',Validators.required],
      comuna:['',Validators.required],
    });

    this.formDireccion.valueChanges.pipe(debounceTime(500)).subscribe(value=>{
      if(value.region!=""){
        this.regiones.forEach((element:any) => {
          if(element.nombre==value.region){
            this.comunas=element.comunas;
          }
        });
      }
    })
    
   }

  ngOnInit(): void {
    this.getRegiones();
    if (typeof(Storage) !== "undefined") {
      
      this.token=localStorage.getItem("token1");
   
      if(this.token!=null){
        this.getTokenCart(this.token);
      }
      if(this.authService.loggedIn()){
        const token=this.authService.getToken();
        this.user = this.authService.decodeToken(token);
        this.getAddress(token);
      }
      if(localStorage.getItem("direction")!=null){
        const getdirection:any=localStorage.getItem("direction");
        const direction=JSON.parse(getdirection);
        this.formDireccion.patchValue({
          nombre:direction.nombre,
          apellido:direction.apellido,
          email:direction.email,
          telefono:direction.telefono,
          direccion:direction.direccion,
          direccion2:direction.direccion2,
          region:direction.region,
          comuna:direction.comuna,
        })
      }
    
    } 

  }
  getRegiones(){
    this.apiService.getRegiones().subscribe(result=>{
      this.regiones=result;
    })
  }
  getTokenCart(token:string){
    this.apiService.getCart(token).subscribe(result=>{
      this.data = result;
      //console.log(this.data);
    });
  }
  submitDireccion(){
    const value=this.formDireccion.value;
    localStorage.setItem('direction', JSON.stringify(value));
    this._router.navigate(['/checkout-shipping']);
  }
  submitDireccionUsers(){
    const value=this.formDireccion.value;
    localStorage.setItem('direction', JSON.stringify(value));
    const token=this.authService.getToken();
    value.id = (this.authService.decodeToken(token))._id;
    
    this.userService.postAddress(value).subscribe(result=>{
      if(result.res){
        sweetAlertBasic(result,"success");
        this.getAddress(token);
      }
    })
  }
  getAddress(token:string){
    const id=(this.authService.decodeToken(token))._id;
    this.userService.getAddress(id).subscribe(result=>{
      this.direction = result.direccion;
    })
  }
  selectDirection(direction:any){
    this.formDireccion.patchValue({
      nombre:direction.nombre,
      apellido:direction.apellido,
      email:direction.email,
      telefono:direction.telefono,
      direccion:direction.direccion,
      direccion2:direction.direccion2,
      region:direction.region,
      comuna:direction.comuna,
    })
  }

}
