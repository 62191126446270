<app-navbar [token]="token1"></app-navbar>
<main>
  <app-breadcrumb [breadcrumb]="breadcrumbitems"></app-breadcrumb>
    <div class="container page-title-overlapis" *ngIf="productos$ | async as products">
        <div class="bg-light box-shadow-lg rounded-lg px-4 py-3 mb-5 ">
            <div class="px-lg-3">
                <div class="row">
                    <div class="col-lg-7 pr-lg-0 pt-lg-4">
                      <p-galleria [(value)]="images" [responsiveOptions]="responsiveOptions" [numVisible]="5" thumbnailsPosition="left"> 
                        <ng-template pTemplate="item" let-item>
                            <img [src]="item.previewImageSrc" style="width: 100%;" />
                        </ng-template>
                        <ng-template pTemplate="thumbnail" let-item>
                            <div class="p-grid p-nogutter p-justify-center">
                                <img [src]="item.thumbnailImageSrc" />
                            </div>
                        </ng-template>
                      </p-galleria>
                    </div>
                    <div class="col-lg-5 pt-4 pt-lg-0">
                        <div class="product-details ml-auto pb-3">
                            <div class="d-flex justify-content-between align-items-center mb-2">
                              <a href="#reviews" *ngIf="promediototal">
                                <div class="star-rating">
                                  <ng-rating-bar class="rating-card" [value]="promediototal.promedio" [ratingCount]="5" [disabled]="true"  colorActive="#fea569" colorDefault="#8c98a4"></ng-rating-bar>
                                </div>
                                <span class="d-inline-block font-size-sm text-body align-middle mt-1 ml-1">{{promediototal.total}} Comentario</span>
                              </a>
                              <button class="btn-wishlist mr-0 mr-lg-n3" type="button" matTooltip="Añadir a la lista de deseos" (click)="addWishlist(products._id)"><i class="far fa-heart"></i></button>
                            </div>
                            <div class="mb-3">
                              <span class="h3 font-weight-normal text-accent mr-1">${{products.precioformateado}}</span>
                              <del *ngIf="products.descuentos!=0" class="text-muted font-size-lg mr-3">${{products.descuentosformateado}}</del>
                              <span *ngIf="products.descuentos!=0" class="badge badge-danger badge-shadow align-middle mt-n2">Sale</span>
                            </div>
                            <div class="font-size-sm mb-4">
                              <span class="text-heading font-weight-medium mr-1">Color:</span><span class="text-muted text-capitalize" id="colorOption">{{colorlabel}}</span></div>
                              <form class="mb-grid-gutter" [formGroup]="formItems" (submit)="addToCart()">
                              <div class="position-relative mr-n4 mb-3">
                                
                                <div class="custom-control custom-option custom-control-inline mb-2" *ngFor="let color of products.colores;let primero = first">
                                  <input class="custom-control-input" type="radio" formControlName="color" name="color" id="{{color.colorcodigo}}" value="{{color.colornombre}}" >
                                  <label class="custom-option-label rounded-circle" for="{{color.colorcodigo}}"><span class="custom-option-color rounded-circle" [ngStyle]="{'background-color': color.colorcodigo}"></span></label>
                                </div>
                                <div class="product-badge product-available mt-n1"><i class="czi-security-check"></i>Producto disponible</div>
                              </div>
                            
                              <div class="form-group">
                                <div class="d-flex justify-content-between align-items-center pb-1">
                                  <label class="font-weight-medium" for="product-size">Tamaño:</label>
                                </div>
                                <select class="custom-select" formControlName="medidas" id="product-size">
                                  <option value="">Seleccione una Talla</option>
                                  <option *ngFor="let tallas of products.tallas">{{tallas}}</option>
                                </select>
                              </div>
                              <div class="form-group d-flex align-items-center">
                                <select class="custom-select mr-3" style="width: 5rem;" formControlName="cantidad">
                                  <option *ngFor="let item of items">{{item}}</option>
                                </select>
                                <button class="btn btn-purple btn-shadow btn-block" type="submit" [disabled]="!formItems.valid"><i class="fas fa-cart-plus font-size-lg mr-2"></i>Añadir al carrito</button>
                              </div>
                            </form>
                            <!-- Product panels-->
                            <div class="accordion mb-4" id="productPanels">
                              <div class="card">
                                <div class="card-header">
                                  <h3 class="accordion-heading">
                                    <a href="#productInfo" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="productInfo" class="collapsed"><i class="fas fa-info-circle text-muted font-size-lg align-middle mt-n1 mr-2"></i>Información del producto<span class="accordion-indicator"><i class="fas fa-chevron-up"></i></span></a></h3>
                                </div>
                                <div class="collapse" id="productInfo" data-parent="#productPanels" style="">
                                  <div class="card-body">
                                    <h6 class="font-size-sm mb-2">Composition</h6>
                                    <ul class="font-size-sm pl-4">
                                      <li>Elastic rib: Cotton 95%, Elastane 5%</li>
                                      <li>Lining: Cotton 100%</li>
                                      <li>Cotton 80%, Polyester 20%</li>
                                    </ul>
                                    <h6 class="font-size-sm mb-2">Art. No.</h6>
                                    <ul class="font-size-sm pl-4 mb-0">
                                      <li>183260098</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header">
                                  <h3 class="accordion-heading">
                                    <a class="collapsed" href="#shippingOptions" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="shippingOptions">
                                      <i class="fas fa-truck text-muted lead align-middle mt-n1 mr-2"></i>Opciones de envío
                                      <span class="accordion-indicator">
                                        <i class="fas fa-chevron-up"></i>
                                      </span>
                                    </a>
                                  </h3>
                                </div>
                                <div class="collapse" id="shippingOptions" data-parent="#productPanels" style="">
                                  <div class="card-body font-size-sm">
                                    <div class="d-flex justify-content-between border-bottom pb-2">
                                      <div>
                                        <div class="font-weight-semibold text-dark">Courier</div>
                                        <div class="font-size-sm text-muted">2 - 4 days</div>
                                      </div>
                                      <div>$26.50</div>
                                    </div>
                                    <div class="d-flex justify-content-between border-bottom py-2">
                                      <div>
                                        <div class="font-weight-semibold text-dark">Local shipping</div>
                                        <div class="font-size-sm text-muted">up to one week</div>
                                      </div>
                                      <div>$10.00</div>
                                    </div>
                                    <div class="d-flex justify-content-between border-bottom py-2">
                                      <div>
                                        <div class="font-weight-semibold text-dark">Flat rate</div>
                                        <div class="font-size-sm text-muted">5 - 7 days</div>
                                      </div>
                                      <div>$33.85</div>
                                    </div>
                                    <div class="d-flex justify-content-between border-bottom py-2">
                                      <div>
                                        <div class="font-weight-semibold text-dark">UPS ground shipping</div>
                                        <div class="font-size-sm text-muted">4 - 6 days</div>
                                      </div>
                                      <div>$18.00</div>
                                    </div>
                                    <div class="d-flex justify-content-between pt-2">
                                      <div>
                                        <div class="font-weight-semibold text-dark">Local pickup from store</div>
                                        <div class="font-size-sm text-muted">—</div>
                                      </div>
                                      <div>$0.00</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="card">
                                <div class="card-header">
                                  <h3 class="accordion-heading">
                                    <a class="collapsed" href="#localStore" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="localStore"><i class="fas fa-map-marker-alt text-muted font-size-lg align-middle mt-n1 mr-2"></i>Encontrar en la tienda local<span class="accordion-indicator"><i class="fas fa-chevron-up"></i></span></a></h3>
                                </div>
                                <div class="collapse" id="localStore" data-parent="#productPanels" style="">
                                  <div class="card-body">
                                    <select class="custom-select">
                                      <option value="">Select your country</option>
                                      <option value="Argentina">Argentina</option>
                                      <option value="Belgium">Belgium</option>
                                      <option value="France">France</option>
                                      <option value="Germany">Germany</option>
                                      <option value="Spain">Spain</option>
                                      <option value="UK">United Kingdom</option>
                                      <option value="USA">USA</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Sharing-->
                            <h6 class="d-inline-block align-middle font-size-base my-2 mr-2">Compartir:</h6>
                            <a class="share-btn sb-twitter mr-2 my-2" href="#"><i class="fab fa-twitter mr-2"></i>Twitter</a>
                            <a class="share-btn sb-instagram mr-2 my-2" href="#"><i class="fab fa-instagram mr-2"></i>Instagram</a>
                            <a class="share-btn sb-facebook my-2" href="#"><i class="fab fa-facebook-f mr-2"></i>Facebook</a>
                          </div>
                    </div>
                </div>
            </div>
        </div>
        <!--descripcion-->
        <div class="row align-items-center py-md-3" *ngIf="products.products!=''">
          
        </div>
        <!--end descripcion-->
        
        <!--Reviews-->
        <div class="border-top border-bottom my-lg-3 py-5"  *ngIf="ratings$ | async as ratings">
          <div class="container pt-md-2">
            <div class="row pb-3">
              <div class="col-lg-4 col-md-5" *ngIf="ratings.ratingpromediototal">
                <h2 class="h3 mb-4">{{ratings.ratingpromediototal.total}} Reseñas</h2>
                <div class="star-rating mr-2">
                  <ng-rating-bar class="rating-card" [value]="ratings.ratingpromediototal.promedio" [ratingCount]="5" [disabled]="true"  colorActive="#fea569" colorDefault="#8c98a4"></ng-rating-bar>
                </div>
                <span class="d-inline-block align-middle">{{ratings.ratingpromediototal.promedio.toFixed(2)}} Calificación general</span>
                <p class="pt-3 font-size-sm text-muted">58 de 74 (77%)<br>Los clientes recomendaron este producto</p>
              </div>
              <div class="col-lg-8 col-md-7" *ngIf="ratings.ratingpromedios">
                <div class="d-flex align-items-center mb-2" *ngFor="let progress of ratings.ratingpromedios">
                  <div class="text-nowrap mr-3"><span class="d-inline-block align-middle text-muted">{{progress.star}}</span><i class="czi-star-filled fas fa-star font-size-xs ml-1"></i></div>
                  <div class="w-100">
                    <div class="progress" style="height: 4px;">
                      <div class="progress-bar bg-success" [ngClass]="{'bg-progress-five': progress.star==5, 'bg-progress-for': progress.star==4, 'bg-progress-tree': progress.star==3,'bg-progress-two': progress.star==2,'bg-progress-one': progress.star==1}" role="progressbar"  [ngStyle]="{'width.%': progress.promedio}"  aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div><span class="text-muted ml-3">{{progress.cantidad}}</span>
                </div>
              </div>

            </div>
            <hr class="mt-4 pb-4 mb-3">
            <div class="mx-auto col-lg-6 pb-4">
                <a class="btn btn-outline-accent btn-block text-center" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                  Escribir Comentario
                </a>
            </div>
            <div class="collapse" id="collapseExample">
              <div class="row">
                
                <div class="col-md-7">
                  <div class="d-flex justify-content-end pb-4">
                    <div class="form-inline flex-nowrap">
                      <label class="text-muted text-nowrap mr-2 d-none d-sm-block" for="sort-reviews">Ordenar por:</label>
                      <select class="custom-select custom-select-sm" id="sort-reviews">
                        <option>El mas nuevo</option>
                        <option>Más antiguo</option>
                        <option>Popular</option>
                        <option>Alta calificación</option>
                        <option>Baja Calificación</option>
                      </select>
                    </div>
                  </div>
        
                    <div class="mh-900 overflow-auto scroll-ui-default">
                      <div class="product-review pb-4 mb-4 border-bottom" *ngFor="let rating of ratings.ratings" >
                        <div class="d-flex mb-3">
                          <div class="media media-ie-fix align-items-center mr-4 pr-2">
                            <img class="rounded-circle" src="{{rating.avatar}}" alt="Avatar" width="50">
                            <div class="media-body pl-3">
                              <h6 class="font-size-sm mb-0">{{rating.nombre}}</h6><span class="font-size-ms text-muted">{{rating.fecha}}</span>
                            </div>
                          </div>
                          <div>
                            <div class="star-rating">
                              <ng-rating-bar class="rating-card" [value]="rating.calificacion" [ratingCount]="5" [disabled]="true"  colorActive="#fea569" colorDefault="#8c98a4"></ng-rating-bar>
                            </div>
                            <div class="font-size-ms text-muted">83% of users found this review helpful</div>
                          </div>
                        </div>
                        <p class="font-size-md mb-2">{{rating.comentarios}}</p>
                        <ul class="list-unstyled font-size-ms pt-1">
                          <li class="mb-1"><span class="font-weight-medium">Pros:&nbsp;</span>{{rating.pros}}</li>
                          <li class="mb-1"><span class="font-weight-medium">Contras:&nbsp;</span>{{rating.contras}}</li>
                        </ul>
                        <div class="text-nowrap">
                          <button class="btn-like" (click)="likeRatings(rating._id)" type="button"><i class="far fa-thumbs-up mr-2 text-green-success"></i>{{rating.like}}</button>
                          <button class="btn-dislike" (click)="dislikeRatings(rating._id)" type="button"><i class="far fa-thumbs-down mr-2 text-red-danger"></i>{{rating.dislike}}</button>
                        </div>
                      </div>
                    </div>
                

                </div>

                <div class="col-md-5 mt-2 pt-4 mt-md-0 pt-md-0" >
                  <div class="bg-secondary py-grid-gutter px-grid-gutter rounded-lg">
                    <h3 class="h4 pb-2">Escribe una reseña</h3>
                    <form class="needs-validation" [formGroup]="formRatings" (submit)="onPostRatings()">
                      <div class="form-group">
                        <label for="review-name">Nombre<span class="text-danger">*</span></label>
                        <input class="form-control" type="text" formControlName="nombre" id="review-name">
                        <div class="invalid-feedback" >por favor, escriba su nombre!</div><small class="form-text text-muted">Se mostrará en el comentario.</small>
                      </div>
                      <div class="form-group">
                        <label for="review-email">Email<span class="text-danger">*</span></label>
                        <input class="form-control" type="email" formControlName="email" id="review-email">
                        <div class="invalid-feedback" >Proporcione una dirección de correo electrónico válida!</div>
                        <small class="form-text text-muted">Solo autenticación: no te enviaremos spam.</small>
                      </div>
                      <div class="form-group">
                        <label for="review-rating">Calificación<span class="text-danger">*</span></label>
                        <select class="custom-select" formControlName="calificacion" id="review-rating">
                          <option value="">Elija calificación</option>
                          <option value="5">5 estrellas</option>
                          <option value="4">4 estrellas</option>
                          <option value="3">3 estrellas</option>
                          <option value="2">2 estrellas</option>
                          <option value="1">1 estrellas</option>
                        </select>
                        <div class="invalid-feedback">¡Elija calificación!</div>
                      </div>
                      <div class="form-group">
                        <label for="review-text">Comentario<span class="text-danger">*</span></label>
                        <textarea class="form-control" rows="6" formControlName="comentarios" id="review-text"></textarea>
                        <div class="invalid-feedback">Por favor escribe una reseña!</div><small class="form-text text-muted">Tu reseña debe tener al menos 50 caracteres.</small>
                      </div>
                      <div class="form-group">
                        <label for="review-pros">Pros</label>
                        <textarea class="form-control" rows="2" placeholder="Separada por comas" formControlName="pros" id="review-pros"></textarea>
                      </div>
                      <div class="form-group mb-4">
                        <label for="review-cons">Contras</label>
                        <textarea class="form-control" rows="2" placeholder="Separada por comas"  formControlName="contras" id="review-cons"></textarea>
                      </div>
                      <button class="btn btn-purple btn-shadow btn-block" type="submit" [disabled]="!formRatings.valid">Enviar una reseña</button>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- end Reviews-->
        <!--Reviews-->

        <div class="container pt-lg-2 pb-5 mb-md-3">
          <h2 class="h3 text-center pb-4">También te puede interesar</h2>
          <div class="py-2">
            <p-carousel [value]="productosrelacionados" [numVisible]="4" [numScroll]="4" [circular]="false" [responsiveOptions]="responsiveOptionsCarousel">
              <ng-template let-product pTemplate="item">
                <div class="card product-card card-static">
                  <button class="btn-wishlist btn-sm" type="button" matTooltip="Agregar mi lista de deseos"><i class="far fa-heart"></i></button>
                  <a class="card-img-top d-block overflow-hidden" routerLink="/productos/{{product._id}}">
                    <img src="{{product.imagenproductos[0]}}" alt="Product" class="loaded tns-complete mh-200">
                  </a>
                  <div class="card-body py-2">
                    <a class="product-meta d-block font-size-xs pb-1" routerLink="/productos/{{product._id}}">{{product.categoria}}</a>
                    <h3 class="product-title font-size-sm">
                    <a routerLink="/productos/{{product._id}}">{{product.titulo}}</a></h3>
                    <div class="d-flex justify-content-between">
                      <div class="product-price text-accent">${{product.precioformateado}}</div>
                      <div class="star-rating">
                        <ng-rating-bar class="rating-card" [value]="product.ratings" [ratingCount]="5" [disabled]="true"  colorActive="#fea569" colorDefault="#8c98a4"></ng-rating-bar>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </div>
        </div>
    </div>
</main>
<app-footer></app-footer>
